// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { Link } from 'react-router-dom';

import ActionCard from './ActionCard';
import * as Helpers from '../helpers';

import orangeIcon from '../assets/images/orange-icon.svg';
import greenIcon from '../assets/images/green-icon.svg';
import pinkIcon from '../assets/images/pink-icon.svg';
import purpleIcon from '../assets/images/purple-icon.svg';

class ActionCardArray extends React.Component {
  render() {
    const {
      info, allWithdrawals, toggle, application_id,
    } = this.props;
    return (
      <div id="dashboard-cards-lower-actions">
        <Link
          style={{ textDecoration: 'none' }}
          to={{
            pathname: '/offer',
          }}
        >
          <ActionCard
            icon={orangeIcon}
            title="Apply"
            background="orange"
          />
        </Link>

        {Number(application_id) === 0 && (
          <Link
            style={{ textDecoration: 'none' }}
            to={{
              pathname: '/repay',
              query: { amount: Helpers.math.divide(info.debt?.current, 100), loanID: 0, value: allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.repayment ? (Helpers.math.divide(info.repayment.minimum.current, 100)) === 1 ? 0 : Helpers.math.divide(info.repayment.minimum.current, 100) : 0 },
            }}
          >
            <ActionCard
              icon={greenIcon}
              title="Repay"
              background="green"
            />
          </Link>
        )}

        <Link style={{ textDecoration: 'none' }} to="/withdrawal">
          <ActionCard
            icon={purpleIcon}
            title="Withdraw"
            background="purple"
          />
        </Link>

        {Number(application_id) === 0 && (
          <ActionCard
            icon={pinkIcon}
            title="Card"
            background="pink"
            onClick={toggle}
          />
        )}
      </div>
    );
  }
}

export default ActionCardArray;
