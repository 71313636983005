import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import jwtDecode from 'jwt-decode';
import 'animate.css';

import './faq.css';
import { Hamburger } from '../../components';
import * as Helpers from '../../helpers';

import handHoldingPhone from '../../assets/images/hand-holding-phone.svg';
import scroll from '../../assets/images/scroll.svg';
import logo from '../../assets/images/carrot-logo-black.png';
// import logo from '../../assets/images/carrot-logo-orange-xmas.png'; // xmas
import hamburger from '../../assets/images/hamburger.svg';
import {
  FaqsContainer, Heading, Section, Caption, AboutSection, ListHeading, ListIcon, ListCaption, Info, ListContent, AboutList,
  AboutDropDown, MobileAboutHeader, ToggleIcon, Drop, Horizontal, HorizontalMobile, Header, Button
} from './FaqsElement';
import { topics } from './Topics';

const Faqs = (logout, history) => {
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [active, setActive] = useState(0);
  const [visibleQuestion, setVisibleQuestion] = useState(0);

  const handleDropDown = () => {
    setShow(!show);
  };

  const chooseActiveTopic = i => () => {
    setActive(i);
    handleDropDown();
    setVisibleQuestion(0);
  };

  const chooseActiveQuestion = i => () => {
    setVisibleQuestion(visibleQuestion === i ? null : i);
  };

  useEffect(() => {
    document.body.classList.remove('no-overflow');
    document.body.classList.add('overflow');
  }, []);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const { firstname } = Helpers.token.get('user:token') && jwtDecode(Helpers.token.get('user:token')) ? jwtDecode(Helpers.token.get('user:token')) : { firstname: '' };

  return (
    <div>
      <Hamburger menu={menu} toggleMenu={toggleMenu} logout={logout} history={history} firstname={firstname && Helpers.capitalizeFirstLetter3(firstname)} />
      <Header>
        <Link style={{ textDecoration: 'none' }} to="/"><img src={logo} alt="scroll" width="80" /></Link>
        <div className="links">
          <Link style={{ textDecoration: 'none' }} to="/dashboard"><div>Dashboard</div></Link>
          <Link style={{ textDecoration: 'none' }} to="/forgot"><div>Password</div></Link>
          <Link style={{ textDecoration: 'none' }} to="/signup">
            <Button>Get started</Button>
          </Link>
        </div>
        <img className="hamburger-icon" src={hamburger} alt="scroll" onClick={toggleMenu} />
      </Header>
      <FaqsContainer>
        <Section>
          <div>
            <Heading>We are always here to help</Heading>
            <Caption>
              Carrot enables our customers to access funds by leveraging their digital asset holdings.
              Our application process takes less time than making a cup of coffee.
              <br />
              <a href="#faq-section">
                <img className="scroll bounce-6" src={scroll} alt="scroll" />
              </a>
            </Caption>
          </div>
          <img className="hand" src={handHoldingPhone} alt="hand holding phone" />
        </Section>
        <HorizontalMobile />
        <AboutSection>
          <AboutList>
            <Horizontal />
            <div id="faq-section">
              {topics.map((topic, i) => (
                <p key={i} onClick={chooseActiveTopic(i)} style={{ color: i === active ? '#FE5000' : '#353535', fontFamily: i === active ? 'Gilroy-Bold' : 'Gilroy-Regular' }}>{topic.heading}</p>
              ))}
            </div>
          </AboutList>
          <Drop>
            <AboutDropDown onClick={handleDropDown}>
              <MobileAboutHeader>{topics[active].heading}</MobileAboutHeader>
              <ToggleIcon>{show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</ToggleIcon>
            </AboutDropDown>
            {show && (
            <div className="animate__animated animate__fadeIn animate__delay-100s" style={{ marginTop: '16px' }}>
              {topics.map((topic, i) => (
                <p key={i} onClick={chooseActiveTopic(i)} style={{ color: i === active ? '#FE5000' : '#353535', fontWeight: i === active ? 'bold' : 'normal' }}>{topic.heading}</p>
              ))}
            </div>
            )}
          </Drop>
          <div>
            {topics[active].qna.map((qna, i) => (
              <Info key={i}>
                <ListContent onClick={chooseActiveQuestion(i)}>
                  <ListIcon>{visibleQuestion !== i ? '+' : '-'}</ListIcon>
                  <ListHeading>{qna.question}</ListHeading>
                </ListContent>
                <ListCaption className={visibleQuestion === i ? 'question-container-open' : 'question-container-close'}>{qna.answer}</ListCaption>
              </Info>
            ))}
          </div>
        </AboutSection>
        {/* <SubmitMail /> */}
        {/* <Footer /> */}
      </FaqsContainer>
    </div>
  );
};

export default Faqs;
