import * as Helpers from '../../helpers';

import { LOADING, ERROR } from './user';

export const PORTFOLIO_GET = 'PORTFOLIO_GET';
export const PORTFOLIO_CLEAR = 'PORTFOLIO_CLEAR';

export const getPortfolio = (applicationID, success, error) => Helpers.api(
  `/portfolio?application_id=${applicationID}`,
  'GET',
  null,
  { error, success },
  { error: ERROR, loading: LOADING, responder: PORTFOLIO_GET }
);

export const clearPortfolio = () => dispatch => {
  dispatch({ type: PORTFOLIO_CLEAR });
};
