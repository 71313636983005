import React, { useEffect, useState } from 'react';
import Button from './Button';
import * as Helpers from '../helpers';

const BlogMail = ({ contact }) => {
  const [getStartedData, setGetStartedData] = useState({
    email: '',
  });

  const handleFormInput = (dataField, newData, existingData = getStartedData) => {
    setGetStartedData({
      ...existingData,
      [dataField]: newData,
    });
  };

  const handleInputChange = e => {
    const dataField = e.target.name;
    const newData = e.target.value;
    handleFormInput(dataField, newData);
  };

  const handleFormSubmit = () => {
    setTimeout(() => {
      contact(
        getStartedData.email,
        true,
        '-',
        '-',
        'Individual',
        () => {
          Helpers.notification.success('We have received your Email.');
        },
        () => {}
      );
    }, 500);
    setTimeout(() => {
      setGetStartedData({
        email: '',
        emailPermit: false,
        fullname: '',
        message: '',
        userType: 'Individual',
      });
    }, 2000);
  };

  useEffect(() => {
    if (window.groove.widget) window.groove.widget.destroy();

    return () => {
      if (window.groove.widget) window.groove.widget.init('697d31d3-878e-400c-bd9d-96926e6917f8', {});
    };
  });

  return (
    <div>
      <section id="email-section">
        <div className="get-started-content">
          <div className="email-section-text">
            <div className="heading-2-text">
              Be the first to receive updates, we won&apos;t spam you!
            </div>
            <div className="content-text">
              Top articles and editorials from the teams at Carrot and our Company announcements, news and openings.
            </div>
          </div>

          <div className="email-section-input">
            <div className="marginTopNBottom">
              <input type="email" className="form-control formBorderRadius formWidth formHeight noBorderOutline paddingTopNBottom" id="exampleFormControlInput1" placeholder="Email address" name="email" onChange={handleInputChange} value={getStartedData.email} />
            </div>
            <Button cta onClick={handleFormSubmit}>Submit</Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogMail;
