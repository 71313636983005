import { combineReducers } from 'redux';

import loan from './loan';
import portfolio from './portfolio';
import user from './user';
import repayment from './repayment';
import withdrawal from './withdrawal';
import contact from './contact';
import application from './application';
import notification from './notification';

export default combineReducers({
  application,
  contact,
  loan,
  notification,
  portfolio,
  repayment,
  user,
  withdrawal,
});
