import React, { useState, useLayoutEffect } from 'react';
import sha256 from 'sha256';
import { Container, Row, Col } from 'react-bootstrap';

import { Empty, SkeletonComponent } from '../../../components';

import recentIcon from '../../../assets/images/recent-icon.svg';
import arrowRight from '../../../assets/images/arrow-right.svg';
import arrowDown from '../../../assets/images/arrow-down.svg';

import * as Helpers from '../../../helpers';

const WithdrawalTable = ({
  data, withdrawalLimit, withdrawalOffset, application_id, loading,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  // eslint-disable-next-line no-unused-vars
  const [winWidth, winHeight] = useWindowSize();

  const toggleMore = key => () => {
    if (key === currentKey) {
      setDropdown(!dropdown);
    } else {
      setCurrentKey(key);
      setDropdown(true);
    }
  };

  if (
    loading.some(
      url => url
        === `/withdrawal?limit=${withdrawalLimit}&offset=${withdrawalOffset}&application_id=${application_id}`
        || url === `/withdrawal?limit=${withdrawalLimit}&offset=${withdrawalOffset}`
    )
  ) {
    return <SkeletonComponent />;
  }

  if (data.length <= 0) return <Empty />;

  return (
    <>
      {data.length > 0 && data.map((withdrawal, i) => (
        <div key={withdrawal.withdrawal_id}>
          {/* Web */}
          {winWidth >= 765 && (
            <div key={`web${withdrawal.withdrawal_id}`} className="dashboard-activity">

              <Container>
                <Row className="align-items-center upper" onClick={toggleMore(i)}>
                  <Col xs={4}>
                    <div className="activity-icon-item">
                      <img src={recentIcon} alt="" className="recent-icon" />
                      <div className="activity-action-items">
                        <div className="activity-default-text bold">
                          Withdrawal
                        </div>
                        <div className="activity-default-text">
                          {withdrawal.pending.substr(0, 10)}
                          {/* 2 days ago */}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={3} className="no-gutters">
                    <div className={`activity-status ${withdrawal.withdrawalStatus}`}>
                      {withdrawal.withdrawalStatus.substr(0, 1).toUpperCase()}
                      {withdrawal.withdrawalStatus.substr(1)}
                    </div>
                  </Col>
                  <Col xs={3}>
                    <div className="activity-default-text value ref">
                      REF:
                      {' '}
                      {sha256(withdrawal.withdrawal_id).substr(0, 8)}
                    </div>
                  </Col>
                  <Col xs={1} className="no-gutters">
                    <div className="activity-default-text value">
                      $
                      {Helpers.formatMoney(Helpers.math.divide(withdrawal.amount, 100))}
                    </div>
                  </Col>
                  <Col xs={1} className="no-gutters-left">
                    <div className="activity-dropdown-icon">
                      {dropdown === true && currentKey === i
                        ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                        : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
                    </div>
                  </Col>
                </Row>
              </Container>

              {dropdown === true && currentKey === i && (

                <Container>
                  <Row className="lower">
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Loan Reference
                        </div>
                        <div className="activity-default-text bold value">
                          {sha256(withdrawal.loan_id).substr(0, 8)}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Amount
                        </div>
                        <div className="activity-default-text bold value">
                          $
                          {Helpers.formatMoney(Helpers.math.divide(withdrawal.amount, 100))}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Requested Currency
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.requestedCurrency}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Rate
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.rate}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="lower">
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Made via
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.processor.processorName}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Date
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.pending}
                        </div>
                      </div>
                    </Col>
                    <Col />
                    <Col />
                  </Row>
                </Container>

              )}
            </div>
          )}

          {/* Mobile */}
          {winWidth < 765 && (
          <div key={`mobile${withdrawal.withdrawal_id}`} className="dashboard-activity">
            <div className="item level-1" onClick={toggleMore(i)}>
              <div className="activity-icon-item">
                <img src={recentIcon} alt="" className="recent-icon" />
                <div className="activity-action-items">
                  <div className="activity-default-text bold">
                    Withdrawal
                  </div>
                  <div className="activity-default-text">
                    {withdrawal.pending.substr(0, 10)}
                  </div>
                </div>
              </div>
              <div className="activity-value-icon">
                <div className="activity-default-text bold value top">
                  $
                  {Helpers.formatMoney(Helpers.math.divide(withdrawal.amount, 100))}
                </div>
                {dropdown === true && currentKey === i
                  ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                  : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
              </div>
            </div>

            {dropdown === true && currentKey === i && (
              <>

                <Container className="level-3-container">
                  <Row className="level-3">
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className={`activity-status ${withdrawal.withdrawalStatus}`}>
                        {withdrawal.withdrawalStatus.substr(0, 1).toUpperCase()}
                        {withdrawal.withdrawalStatus.substr(1)}
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Withdrawal Reference
                        </div>
                        <div className="activity-default-text bold value">
                          {sha256(withdrawal.withdrawal_id).substr(0, 8)}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Loan Reference
                        </div>
                        <div className="activity-default-text bold value">
                          {sha256(withdrawal.loan_id).substr(0, 8)}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Amount
                        </div>
                        <div className="activity-default-text bold value">
                          $
                          {Helpers.formatMoney(Helpers.math.divide(withdrawal.amount, 100))}
                        </div>
                      </div>
                    </Col>

                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Requested Currency
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.requestedCurrency}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Rate
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.rate}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Made via
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.processor.processorName}
                        </div>
                      </div>
                    </Col>

                    <Col xs={6} sm={6} className="no-gutters-right col-margin">
                      <div className="activity-action-items">
                        <div className="activity-default-text bold not-black">
                          Date
                        </div>
                        <div className="activity-default-text bold value">
                          {withdrawal.pending}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={6} className="no-gutters-right col-margin" />
                  </Row>
                </Container>

              </>
            )}

          </div>
          )}
        </div>
      ))}
    </>
  );
};

export default WithdrawalTable;
