// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from 'react-icons/fi';

import * as Helpers from '../helpers';

class DetailsCard extends React.Component {
  render() {
    const {
      info, allWithdrawals, credit, day, month, year, currentMonth, futureMonth,
      blacklist,
    } = this.props;
    return (
      <div id="dashboard-card-upper-details">
        <div className="item" title={`$${Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info?.debt?.current ? Helpers.math.divide(info?.debt?.current, 100) : 0)}`}>
          <OverlayTrigger
            placement="right"
            overlay={(
              <Tooltip id={`tooltip-${'right'}`} className="tooltip-right1">
                This is the total amount due (outstanding principal + minimum interest)
                <br />
                <br />
                Total debt for
                {' '}
                {currentMonth}
                : $
                {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info?.debt?.current ? Helpers.math.divide(info?.debt?.current, 100) : 0)}
                <br />
                Total debt for
                {' '}
                {futureMonth}
                : $
                {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.debt?.future ? Helpers.math.divide(info.debt?.future, 100) : 0)}
              </Tooltip>
              )}
            offset={[0, -67]}
          >
            <div className="text">
              Principal
              <FiInfo className="dashboard-info-icon icon-green" />
            </div>
          </OverlayTrigger>
          <div className="value">
            $
            {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info?.debt?.current ? Helpers.math.divide(info?.debt?.current, 100) : 0)}
          </div>
        </div>

        <div className="item" title={`$${Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.repayment ? Helpers.math.divide(info.repayment.minimum.current, 100) : 0)}`}>
          <OverlayTrigger
            placement="left"
            overlay={(
              <Tooltip id={`tooltip-${'left'}`} className="tooltip-left1">
                This is the amount due on your next repayment date
                <br />
                <br />
                Minimum repayment for
                {' '}
                {currentMonth}
                : $
                {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.repayment ? (Helpers.math.divide(info.repayment.minimum.current, 100)) === 1 ? 0 : Helpers.math.divide(info.repayment.minimum.current, 100) : 0)}
                <br />
                Minimum repayment for
                {' '}
                {futureMonth}
                : $
                {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.repayment ? (Helpers.math.divide(info.repayment.minimum.future, 100)) === 1 ? 0 : Helpers.math.divide(info.repayment.minimum.future, 100) : 0)}
              </Tooltip>
            )}
            offset={[0, -95]}
          >
            <div className="text">
              Next Amount Due
              <FiInfo className="dashboard-info-icon icon-green" />
            </div>
          </OverlayTrigger>
          <div className="value">
            $
            {Helpers.formatMoney(allWithdrawals.length === 0 && info.balance <= 0 ? 0 : info.repayment ? (Helpers.math.divide(info.repayment.minimum.current, 100)) === 1 ? 0 : Helpers.math.divide(info.repayment.minimum.current, 100) : 0)}
          </div>
        </div>

        <div className="item" title={`$${Helpers.formatMoney(Helpers.math.divide(credit, 100))}`}>
          <OverlayTrigger
            placement="right"
            overlay={(
              <Tooltip id={`tooltip-${'right'}`} className="tooltip-right2">
                This is the amount of money available for you to take
              </Tooltip>
            )}
            offset={[0, -30]}
          >
            <div className="text">
              Available Credit
              <FiInfo className="dashboard-info-icon icon-green" />
            </div>
          </OverlayTrigger>
          <div className="value">
            {blacklist ? '' : '$'}
            {blacklist ? '********' : Helpers.formatMoney(Helpers.math.divide(credit, 100))}
          </div>
        </div>

        <div className="item" title={allWithdrawals.length === 0 && info.balance <= 0 ? '-' : info.repayment ? `${day}.${month}.${year}` : '-'}>
          <OverlayTrigger
            placement="left"
            overlay={(
              <Tooltip id={`tooltip-${'left'}`} className="tooltip-left2">
                This is your next payment date
              </Tooltip>
            )}
            offset={[0, -105]}
          >
            <div className="text">
              Next Payment Date
              <FiInfo className="dashboard-info-icon icon-green" />
            </div>
          </OverlayTrigger>
          <div className="value">
            {allWithdrawals.length === 0 && info.balance <= 0 ? '-' : info.repayment ? `${day}.${month}.${year}` : '-'}
          </div>
        </div>

      </div>
    );
  }
}

export default DetailsCard;
