import React from 'react';
import Carousel from 'react-elastic-carousel';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import image1 from '../assets/images/image1.png';
import image from '../assets/images/image.png';
import section1 from '../assets/images/section1.png';
import section2 from '../assets/images/section2.png';
import section3 from '../assets/images/section3.png';
import group1 from '../assets/images/group1.png';
import group2 from '../assets/images/group2.png';
import group3 from '../assets/images/group3.png';

class Review extends React.Component {
  state = {
    breakPoints: [
      { itemsToShow: 1, width: 1 },
      { itemsToShow: 2, width: 550 },
      { itemsToShow: 3, width: 768 },
    ],

  }

  render() {
    const { breakPoints } = this.state;
    return (
      <div id="individual-section">
        <div className="individual-section">
          <div>
            <div className="individual-section__honour">
              <h3>
                We&apos;re honoured to serve thousands
                <br />
                {' '}
                of users all over the world
              </h3>
              <div className="individual-section__arrow">
                <BsArrowLeft className="individual-arrow-left" onClick={() => this.carousel.slidePrev()} />
                <BsArrowRight className="individual-arrow-right" onClick={() => this.carousel.slideNext()} />
              </div>
            </div>
            <div className="individual-section__review">
              <h5 className="individual-review">Reviews</h5>
              <hr
                style={{
                  background: '#222831',
                  height: '2px',
                  marginLeft: '15px',
                  marginTop: '11px',
                  width: '73px',
                }}
              />
            </div>
            <Carousel
              // eslint-disable-next-line no-return-assign
              ref={ref => (this.carousel = ref)}
              breakPoints={breakPoints}
            >
              <div className="individual-section__row">
                <div className="individual-school">
                  <p>
                    I needed money to complete my school fees,
                    went through the carrot process and had the money in my account in minutes!
                    {' '}
                    <br />
                    Thank you Carrot!
                  </p>
                  <div className="individual-image-content" id="individual-image2">
                    <img src={image1} alt="" />
                    <p className="individual-content">
                      <h6>James Mbakwe</h6>
                      UI Designer at Google
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-customer">
                  <p>
                    Special shout out to the Carrot customer
                    support team for answering all of my questions
                    and giving me a joyful experience
                  </p>
                  <div className="individual-image-content" id="individual-image">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Shiron Bisong</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-club">
                  <p>
                    Carrot saved me at the club. Got money for payments very fast
                  </p>
                  <div className="individual-image-content" id="individual-image1">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Raymond Thual</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-school">
                  <p>
                    I needed money to complete my school fees,
                    went through the carrot process and had the money in my account in minutes!
                    {' '}
                    <br />
                    Thank you Carrot!
                  </p>
                  <div className="individual-image-content" id="individual-image2">
                    <img src={image1} alt="" />
                    <p className="individual-content">
                      <h6>James Mbakwe</h6>
                      UI Designer at Google
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-customer">
                  <p>
                    Special shout out to the Carrot customer
                    support team for answering all of my questions
                    and giving me a joyful experience
                  </p>
                  <div className="individual-image-content" id="individual-image">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Shiron Bisong</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-club">
                  <p>
                    Carrot saved me at the club. Got money for payments very fast
                  </p>
                  <div className="individual-image-content" id="individual-image1">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Raymond Thual</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-school">
                  <p>
                    I needed money to complete my school fees,
                    went through the carrot process and had the money in my account in minutes!
                    {' '}
                    <br />
                    Thank you Carrot!
                  </p>
                  <div className="individual-image-content" id="individual-image2">
                    <img src={image1} alt="" />
                    <p className="individual-content">
                      <h6>James Mbakwe</h6>
                      UI Designer at Google
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-customer">
                  <p>
                    Special shout out to the Carrot customer
                    support team for answering all of my questions
                    and giving me a joyful experience
                  </p>
                  <div className="individual-image-content" id="individual-image">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Shiron Bisong</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
              <div className="individual-section__row">
                <div className="individual-club">
                  <p>
                    Carrot saved me at the club. Got money for payments very fast
                  </p>
                  <div className="individual-image-content" id="individual-image1">
                    <img src={image} alt="" />
                    <p className="individual-content">
                      <h6>Raymond Thual</h6>
                      Professional Freelancer
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
          <div className="individual-section__work">
            <div className="work-content__row">
              <div>
                <img src={section1} alt="" id="sec" />
                <p className="work-content">
                  <img src={group1} alt="" />
                  <p style={{ marginLeft: '10px' }}>
                    Sign up on carrot from a
                    <br />
                    {' '}
                    partner application
                  </p>
                </p>
              </div>

              <div>
                <img src={section3} alt="" id="sec" />
                <p className="work-content">
                  <img src={group2} alt="" />
                  <p style={{ marginLeft: '10px' }}>
                    Total amount
                    <br />
                    {' '}
                    withdrawable is calculated
                  </p>
                </p>
              </div>

              <div>
                <img src={section2} alt="" id="sec" />
                <p className="work-content">
                  <img src={group3} alt="" />
                  <p style={{ marginLeft: '10px' }}>
                    Decide amount and apply
                    <br />
                    {' '}
                    for cash
                  </p>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Review;
