import * as Helpers from '../../helpers';

import { ERROR, LOADING } from './user';

export const FETCH_ALL_NOTIFICATIONS = 'FETCH_ALL_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export const getNotifications = (limit, offset, type, success) => Helpers.api(
  `/notifications/${type}?limit=${limit || 10}&offset=${offset || 0}`,
  'GET',
  null,
  { success },
  { error: ERROR, loading: LOADING, responder: FETCH_ALL_NOTIFICATIONS }
);

export const markNotificationsAsRead = (success, error) => Helpers.api(
  '/notifications',
  'PUT',
  null,
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATE_NOTIFICATIONS }
);

export const markNotificationsAsDeleted = (success, error) => Helpers.api(
  '/notifications',
  'DELETE',
  null,
  { error, success },
  { error: ERROR, loading: LOADING, responder: UPDATE_NOTIFICATIONS }
);
