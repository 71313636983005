import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';

import logo from '../assets/images/carrot-logo-orange.png';
// import logo from '../assets/images/carrot-logo-orange-xmas.png'; // xmas

import arrowLeft from '../assets/images/arrow-left.svg';

class Header extends Component {
  render() {
    const {
      back, noPrev, hm, title, dropdown,
    } = this.props;
    return (
      <div id="header">
        <div id="mobile" className="mobile-display-block web-display-none">
          {noPrev && <div />}
          {!noPrev && (<img src={arrowLeft} className="header-back-image" alt="back" onClick={back} />)}
          <div className="mobile-header-logo">
            <Link to={(title === 'Signin' || title === 'Signup') ? '/' : '/dashboard'}>
              <img src={logo} alt="Carrot Logo" />
            </Link>
          </div>
          {dropdown || <div />}
        </div>

        <div className="mobile-display-none web-display-block" style={{ margin: hm }}>
          {!noPrev && (<IoIosArrowBack className="header-back-icon" onClick={back} />)}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  noPrev: PropTypes.bool,
  title: PropTypes.string,
};

export default Header;
