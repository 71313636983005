import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

import * as Helpers from '../helpers';

class Pagination extends Component {
    state = {
      pages: 0,
    }

    componentDidMount = () => {
      const { total, perPage } = this.props;
      this.setState({
        pages: Math.ceil(Helpers.math.divide(total, perPage)),
      });
    }

    handlePageClick = e => {
      const { handlePagination } = this.props;
      handlePagination(e);
    }

    render() {
      const { pages } = this.state;
      const { current } = this.props;

      return (
        <ReactPaginate
          previousLabel="<"
          nextLabel=">"
          containerClassName="pagination"
          activeClassName="active"
          pageCount={pages}
          forcePage={current}
          onPageChange={this.handlePageClick}
          breakLabel="..."
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
        />
      );
    }
}

export default Pagination;
