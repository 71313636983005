import * as Helpers from '../../helpers';
import { LOAN_CLEAR } from './loan';
import { REPAYMENT_CLEAR } from './repayment';
import { WITHDRAWAL_CLEAR } from './withdrawal';
import { PORTFOLIO_CLEAR } from './portfolio';
import { APPLICATION_CLEAR } from './application';
import { CLEAR_USER } from './user';

export const LOGOUT = 'LOGOUT';

export const logout = history => dispatch => {
  Helpers.token.remove();

  if (window.groove.widget) window.groove.widget.logout();

  dispatch({
    payload: null,
    type: LOGOUT,
  });
  dispatch({ type: LOAN_CLEAR });
  dispatch({ type: REPAYMENT_CLEAR });
  dispatch({ type: WITHDRAWAL_CLEAR });
  dispatch({ type: PORTFOLIO_CLEAR });
  dispatch({ type: APPLICATION_CLEAR });
  dispatch({ type: CLEAR_USER });

  history.push('/signin');
};
