import React from 'react';

const AUMCard = ({
  image, Icon, Heading, dec,
}) => (
  <div>
    <div className="Square">
      <div className="MainImage">
        <div className="battery">
          <img src={image} alt="sd" className="img-fluid" />
        </div>
        <div className="icon">
          <img src={Icon} alt="sdsd" />
        </div>
      </div>
      <p className="heading-text">{Heading}</p>
      <p className="content-text">{dec}</p>
    </div>
  </div>
);

export default AUMCard;
