export default function formatMoney(numberP, decPlacesP = 2, displayFullLength) {
  const decPlacesPP = Math.abs(decPlacesP);
  // eslint-disable-next-line no-restricted-globals
  const decPlaces = isNaN(decPlacesPP) ? 2 : decPlacesPP;
  const sign = numberP < 0 ? '-' : '';
  const number = Math.abs(Number(numberP || 0)).toFixed(decPlaces);

  if (!displayFullLength) {
    if (number >= 1000000) {
      return `${sign}${(number / 1000000).toFixed(decPlaces)}M`;
    }

    if (number >= 100000) {
      return `${sign}${(number / 1000).toFixed(decPlaces)}K`;
    }
  }

  return sign + number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
