import React, { Component } from 'react';

class ActionCard extends Component {
  render() {
    const {
      icon, title, background, onClick,
    } = this.props;
    return (
      <div className="dashboard-action-card" onClick={onClick} title={title}>
        <div className={`action-card-circle background-${background}`}>
          <img src={icon} alt="" />
        </div>
        <div className="title">
          {title}
        </div>
      </div>
    );
  }
}

export default ActionCard;
