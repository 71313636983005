import React, { useState, useLayoutEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const SkeletonComponent = () => {
  const [winWidth] = useWindowSize();

  return (
    <>
      {winWidth >= 765 ? (
        <Skeleton
          count={4}
          style={{
            margin: '5px 10px',
            minHeight: '75px',
            minWidth: '575px',
            width: 'calc(100% - 20px)',
          }}
        />
      ) : (
        <Skeleton
          count={4}
          style={{
            margin: '5px 10px',
            minHeight: '72px',
            minWidth: '296.56px',
            width: 'calc(100% - 10px)',
          }}
        />
      )}
    </>
  );
};

export default SkeletonComponent;
