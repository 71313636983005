import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from 'react-icons/fi';

import convert from '../assets/images/convert.png';

class Rates extends Component {
  render() {
    const {
      windowWidth, userData,
    } = this.props;
    return (
      <div id="rates">
        <span>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id={`tooltip-${'bottom'}`} className="tooltip-rate">
                This is the Buy Rate
              </Tooltip>
              )}
            offset={windowWidth < 765 ? [0, 8] : [0, 7]}
          >
            <div>
              <FiInfo className="dashboard-info-icon icon-white" />
              &nbsp;&nbsp;
              Buy Rate:
              &nbsp;&nbsp;
              $1
              <img className="icon tiny" src={convert} alt="Conversion" />
              {'\u20A6'}
              {(userData && userData.info.rates && userData.info.rates.buy) || 0}
            </div>
          </OverlayTrigger>
        </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id={`tooltip-${'bottom'}`} className="tooltip-rate">
                This is the Sell Rate
              </Tooltip>
              )}
            offset={windowWidth < 765 ? [0, 8] : [0, 7]}
          >
            <div>
              <FiInfo className="dashboard-info-icon icon-white" />
              &nbsp;&nbsp;
              Sell Rate:
              &nbsp;&nbsp;
              $1
              <img className="icon tiny" src={convert} alt="Conversion" />
              {'\u20A6'}
              {(userData && userData.info.rates && userData.info.rates.sell) || 0}
            </div>
          </OverlayTrigger>
        </span>
      </div>
    );
  }
}

Rates.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

export default Rates;
