import React from 'react';
import { Link } from 'react-router-dom';

import Request from './Request';

import {
  Button, Page, Input, Successful, Loading
} from '../../components';

import * as Helpers from '../../helpers';

import bambooLogo from '../../assets/images/bamboo.svg';
import ssl from '../../assets/images/ssl.svg';
import tickCircle from '../../assets/images/tick-circle-green.svg';
import cancel from '../../assets/images/cancel.svg';

class Offer extends React.Component {
  state = {
    query: '',
  };

  secure = (
    <>
      <img src={ssl} alt="SSL" />
      {' '}
      Your information is encrypted and securely transmitted using SSL.
    </>
  );

  constructor() {
    super();
    const thirdParty = this.getQueryVariable('applicationid');

    this.state = {
      complete: false,
      data: {
        amount: 0,
        legal: '',
        password: '',
        passwordConfirmation: '',
        tenure: '0',
      },
      showPassword: false,
      // --TODO: THERE ARE CERTAINLY BETTER WAYS TO HANDLE THIS!--
      /* ONBOARDING STEPS */
      // TO RE-ORDER THE ONBOARDING STEPS SIMPLY
      // CHANGE THE ORDER IN THE `steps.all` ARRAY
      // THEN ENSURE THE LAST STEP CALLS `this.lastStep`
      // ANY STEP THAT HAS A SPECIAL COMPONENT CAN BE
      // RENDERED BY USING THE `Component` key
      // FOR ANY STEP TO PROCEED CALL `this.next`
      steps: {
        all: [
          {
            Component: comProps => <Request {...comProps} thirdParty={thirdParty} />,
          },
          // PASSWORD
          {
            heading: 'Create Password',
            key: 'password-passwordConfirmation',
            subheading: 'Enter your secret password, you will use this to access your account.',
            validate: (state, newProps) => {
              // eslint-disable-next-line no-console
              console.log('STEP: PASSWORD');
              const { data } = state;
              const { updateUser, user: { password } } = newProps;
              if (password) {
                return this.next();
              }

              let error = '';
              if (!data.password) {
                error = 'You need to set your password.';
              } else if (
                data.password.length < 8
                || !/[A-Z]/.test(data.password)
                || !/[a-z]/.test(data.password)
                || !/[0-9]/.test(data.password)
                // eslint-disable-next-line no-useless-escape
                || !/[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/.test(data.password)
              ) {
                // g characters: at least 8 digits, uppercase, lowercase, at least 1 number, 1 non-alphanumeric character '),
                // error = 'Your password must be a minimum of 8 characters, contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol.';
                error = 'Your password does not meet the listed requirements.';
              } else if (data.password !== data.passwordConfirmation) {
                error = 'Password and Password Confirmation fields must match.';
              }

              if (!error) {
                return updateUser({
                  password: data.password,
                  passwordConfirmation: data.passwordConfirmation,
                }, {
                  success: () => {
                    Helpers.notification.success('You have successfully set your password.');
                    this.next();
                  },
                });
              }
              return Helpers.notification.error(error);
            },
          },
          // LEGAL
          {
            heading: 'Terms and Conditions',
            key: 'legal',
            placeholder: 'Enter your full name',
            subheading: (
              <>
                By finishing this application, you acknowledge
                that you have read, understood, and agree to all of
                Carrot
                {'\''}
                s
                {' '}
                <Link style={{ color: '#FE5000' }} to="/user-agreement" target="_blank">User Agreement</Link>
                {' '}
                and
                {' '}
                <Link style={{ color: '#FE5000' }} to="/privacy-policy" target="_blank">Privacy Policy</Link>
              </>
            ),
            validate: (state, newProps) => {
              // eslint-disable-next-line no-console
              console.log('STEP: LEGAL');
              const { data: { amount, tenure } } = state;
              const { portfolio: { all: portfolioArray } } = newProps;
              const currentPortfolio = portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(Helpers.token.get('user:application_id')))[0];
              const { portfoliolegal: legal } = currentPortfolio;
              if (legal === '1') {
                return this.lastStep(amount, tenure);
              }

              const { data } = state;
              const parts = data.legal.trim().split(/(\s+)/).filter(items => !/(\s+)/.test(items));
              const { user: { firstname, lastname } } = newProps;
              const error = 'You need to enter your full name to accept the legal agreements.';
              try {
                const fullname = `${firstname} ${lastname}`;
                const fullnameArr = fullname
                  .toLowerCase()
                  .trim()
                  .split(/(\s+)/)
                  .filter(items => !/(\s+)/.test(items));
                let match = 0;
                fullnameArr.forEach(name1 => {
                  if (parts.some(name2 => name2 === name1)) {
                    match += 1;
                  }
                });
                if (match >= 2) {
                  return this.lastStep(amount, tenure);
                }
                return Helpers.notification.error(error);
              } catch {
                return Helpers.notification.error(error);
              }
            },
          },
        ],
        current: 0,
      },
    };
  }

  componentDidMount() {
    const application_id = this.getQueryVariable('applicationid');
    if (application_id) {
      Helpers.token.set(application_id, 'user:application_id');
    }
  }

  getQueryVariable = variable => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) return pair[1];
    }
    return false;
  }

  success = () => {
    // const { addCard, load } = this.props;
    this.next();
    // addCard(data.reference, response => {
    //   Helpers.notification.success(response.message);
    //   load();
    // });
  }

  close = () => {
    const { steps: { all, current } } = this.state;
    this.setState({ steps: { all, current: current - 1 } });
  }

  lastStep = (amount, tenure) => {
    const application_id = this.getQueryVariable('applicationid') || Number(Helpers.token.get('user:application_id'));
    if (application_id) {
      const { requestLoan, history } = this.props;
      requestLoan(
        application_id,
        Math.floor(Helpers.math.multiply(amount, 100)),
        tenure,
        data => {
          // eslint-disable-next-line no-console
          console.log(data.message);
          this.setState({ complete: true, loading: false });
        },
        () => {
          history.push('/dashboard');
        }
      );
    } else {
      Helpers.notification.error('No application was selected.');
    }
  }

  change = type => e => {
    const { data } = this.state;
    this.setState({ data: { ...data, [type]: e.target.value } });
  }

  next = skipTo => {
    const { load } = this.props;
    const { steps: { current, all } } = this.state;
    const nextCurrent = skipTo || current + 1 > all.length - 1 ? all.length - 1 : current + 1;

    // When next is called, simply advance to the next screen
    // and call it's validate function
    // Individual screens validate input and call next if validations pass
    this.setState({
      steps: {
        all,
        current: nextCurrent,
      },
    }, () => {
      this.validateStep(nextCurrent)();
      load();
    });
  }

  validateStep = current => () => {
    const { steps: { all } } = this.state;
    // eslint-disable-next-line no-console
    console.log('VALIDATING:', all[current].key);

    if (all[current].validate) all[current].validate(this.state, this.props);
  }

  setAmount = amount => {
    const { data } = this.state;
    this.setState({ data: { ...data, amount } });
  }

  setPasswordType = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger,
    });
  }

  setTenure = e => {
    const { data } = this.state;
    this.setState({
      data: { ...data, tenure: e.target.value },
    });
  }

  noop = () => null;

  render() {
    const {
      complete, data, steps: { current, all }, showPassword, amount,
    } = this.state;
    const { tenure } = data;
    const { user: { loading: loanRequestLoading }, history } = this.props;
    const {
      Component, key, footer, heading,
      subheading, placeholder,
    } = all[current];
    const loading = loanRequestLoading.some(endpoint => endpoint === '/loan');

    if (loading) {
      return <Loading size="big" />;
    } if (complete) {
      return (
        <Successful
          title="We have received your application"
          subtitle="Your credit request is now being processed. You will be notified in less than 15 minutes."
          background="illustration-2"
          history={history}
        />
      );
    } if (Component) {
      // Component is <Loan />
      return (
        <Component
          {...this.props}

          // For this.state.current = 0
          amount={amount}
          setAmount={this.setAmount}
          tenure={tenure}
          setTenure={this.setTenure}
          next={this.next}

          // For this.state.current = 1
          success={this.success}
          close={this.close}
        />
      );
    }

    // Split the key and loop it's parts
    // Use this.state.steps.all to render
    // various forms depending on the current
    // step (this.state.steps.current)
    const parts = key && key.split('-');
    return (
      <Page
        title="Offer"
        background="illustration-2"
        hasHeader
        hasLogo
        back={this.back()}
        hm="50px 0 0 0"
        lm="20px 0 0 0"
        footer={footer || this.secure}
        alignContentMobile="align-left"
      >
        <div>
          <div className="heading onboarding">{heading}</div>
          <div className="subheading onboarding">{subheading}</div>
        </div>
        {
          parts
          && Array.from(new Array(parts.length)).map((_, i) => {
            const newKey = parts[i];
            return (
              <React.Fragment key={i}>
                <div className="onboarding-input password_input_show">
                  <Input
                    placeholder={placeholder || `${Helpers.capitalizeFirstLetter3(newKey.replace(/([A-Z])/, ' $1').toLowerCase())}`}
                    type={/password/.test(newKey) ? showPassword ? 'text' : 'password' : 'text'}
                    onChange={this.change(newKey)}
                    value={data[newKey]}
                  />
                  {/password/.test(newKey) && <p alt="Show password" className="password_show_icon" onClick={this.setPasswordType}>{showPassword ? 'Hide' : 'Show'}</p>}
                </div>
              </React.Fragment>
            );
          })
        }

        {key === 'password-passwordConfirmation' && (
          <div className="password-evaluation">
            <div className="item">
              <img src={!(data.password.length < 8) ? tickCircle : cancel} alt="" />
              <span>Min 8 characters</span>
            </div>
            <div className="item">
              <img src={/[A-Z]/.test(data.password) ? tickCircle : cancel} alt="" />
              <span>1 uppercase letter</span>
            </div>
            <div className="item">
              <img src={/[a-z]/.test(data.password) ? tickCircle : cancel} alt="" />
              <span>1 lowercase letter</span>
            </div>
            <div className="item">
              <img src={/[0-9]/.test(data.password) ? tickCircle : cancel} alt="" />
              <span>1 number</span>
            </div>
            <div className="item">
              <img
                src={
                // eslint-disable-next-line no-useless-escape
                /[-#!$@%^&*()_+|~=`{}\[\]:";'<>?,.\/ ]/.test(data.password) ? tickCircle : cancel
              }
                alt=""
              />
              <span>1 symbol</span>
            </div>
          </div>
        )}

        <div className="onboarding-button">
          <Button
            onClick={this.validateStep(current)}
            size="large"
          >
            {
              current === 0
                ? (
                  <>
                    Sign up with
                    <div className="divider" />
                    {' '}
                    Bamboo
                    <img className="button-logo" src={bambooLogo} alt="Bamboo Logo" />
                  </>
                )
                : current === all.length - 1 ? 'REQUEST LOAN' : 'NEXT'
            }
          </Button>
        </div>
      </Page>
    );
  }
}

export default Offer;
