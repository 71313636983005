import React from 'react';

class NotificationLoader extends React.Component {
  render() {
    return (
      <div className="notificationLoader">
        <div className="notificationLoaderCircle" />
        <div className="notificationLoaderRight">
          <div className="notificationLoaderTop">
            <div className="notificationLoaderTopLeft" />
            <div className="notificationLoaderTopRight" />
          </div>
          <div className="notificationLoaderMiddle" />
          <div className="notificationLoaderBottom" />
        </div>
      </div>
    );
  }
}

export default NotificationLoader;
