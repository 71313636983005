
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Banner, Button
} from '../../components';

import arrowLeft from '../../assets/images/arrow-left.svg';
import damilojuProfileImage from '../../assets/images/damiloju-profile-image.svg';

class SigninOptions extends React.Component {
  handleSigninOption = choice => {
    const { next } = this.props;
    next(choice);
  }

  handleResendLink = () => {
    const { resend } = this.props;
    resend();
  }

  render() {
    const { data: { application_id, email, mobile } } = this.props;
    return (
      <>
        <Banner />

        <div id="signwithPage">
          <div id="signwith-main-page">
            <div id="signwith-back-button">
              <img src={arrowLeft} alt="" onClick={this.back} />
            </div>

            <div id="signwith-main-content">
              <div id="signwith-cards-left">
                <div className="signwith-container-left">
                  <div className="signwith-container-left-inner-div">
                    <h5>Log In</h5>

                    <h4>Welcome Back!</h4>

                    <p>
                      We sent an email to you
                      {email && (
                        <>
                          {' '}
                          at
                          {' '}
                          <span>{`${email.slice(0, 3)}********${email.slice(-5)}`}</span>
                        </>
                      )}
                      . It has a
                      {' '}
                      <br />
                      {' '}
                      magic link that will sign you in instantly.
                    </p>

                    <Button
                      background="light"
                      onClick={this.handleResendLink}
                    >
                      Resend Link
                    </Button>

                    <div className="sign-link">
                      <div className="type" onClick={() => this.handleSigninOption('password')}>Sign In with Password</div>
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={{
                          pathname: '/signon',
                          query: { application_id, mobile },
                        }}
                      >
                        <div className="type">Request OTP to Sign In</div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="credit-interest">
                  <a href="/faqs">See how we collect interest on credit?</a>
                  <a className="help" href="/faqs">Need Help?</a>
                </div>
              </div>

              <div className="signwith-container-right">
                <div>
                  <h4>
                    Use your existing digital assets
                  </h4>

                  <p>
                    OMG! Carrot is amazing, I was able to easily
                    withdraw credit using my Bamboo portfolio,
                    the process was seamless.
                    <br />
                    Everyone should use Carrot!
                  </p>

                  <div className="profile">
                    <h6>Damiloju Morenikeji</h6>
                    <img src={damilojuProfileImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default SigninOptions;
