import qs from 'qs';

import * as Helpers from '../../helpers';

export const BALANCE = 'BALANCE';
export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const SIGNIN = 'SIGNIN';
export const SIGNUP = 'SIGNUP';
export const LOAD = 'LOAD';
export const CLEAR_USER = 'CLEAR_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const RESET = 'RESET';
export const OTP = 'OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const REGISTER = 'REGISTER';

export const signin = (emailOrMobile, password, history, persistLogin, signinType) => Helpers.api(
  '/user/signin',
  'POST',
  {
    password, [signinType]: emailOrMobile,
  },
  {
    success: response => setTimeout(() => {
      if (persistLogin) {
        if (Helpers.token.get('user') !== emailOrMobile) {
          Helpers.token.set(emailOrMobile, 'user');
        }
      } else {
        Helpers.token.remove('user');
      }
      Helpers.token.set(response.data.token);
      Helpers.token.set(response.data.application_id, 'user:application_id');
      const queryString = qs.parse(window.location.search.slice(1));
      if (Helpers.token.get('user:token') && queryString && queryString.redirect) {
        window.location = queryString.redirect;
      } else {
        history.push('/dashboard');
      }
    }, 500),
  },
  { error: ERROR, loading: LOADING, responder: SIGNIN }
);

export const signon = (application_id, emailOrMobile, signinType, code, success, error) => Helpers.api(
  '/signin/link',
  'POST',
  { application_id, code, [signinType]: emailOrMobile },
  { error, success },
  { error: ERROR, loading: LOADING, responder: SIGNUP }
);

export const clearUser = () => dispatch => {
  dispatch({ type: CLEAR_USER });
};

export const load = (success, error, user_id, application_id) => Helpers.api(
  `/user${user_id ? `/${user_id}` : ''}${application_id ? `?application_id=${application_id}` : ''}`,
  'GET',
  null,
  { error, success },
  { error: ERROR, loading: LOADING, responder: LOAD }
);

export const updateUser = (data, callback) => Helpers.api(
  '/user',
  'PUT',
  { data },
  { ...callback },
  { error: ERROR, loading: LOADING, responder: UPDATE_USER }
);

export const reset = (data, success, error) => Helpers.api(
  '/user/reset',
  'PUT',
  { data },
  { error, success },
  { error: ERROR, loading: LOADING, responder: RESET }
);

export const otp = (emailOrMobile, otpType, success, error) => Helpers.api(
  '/user/otp',
  'POST',
  { [otpType]: emailOrMobile },
  { error, success },
  { error: ERROR, loading: LOADING, responder: OTP }
);

export const verifyOtp = (data, success, error) => Helpers.api(
  '/user/otp/verify',
  'POST',
  { data },
  { error, success },
  { error: ERROR, loading: LOADING, responder: VERIFY_OTP }
);

export const signup = (application_id, emailOrMobile, getParamsObj, success, error) => Helpers.api(
  `/signon/${application_id}/${emailOrMobile}`,
  'POST',
  { ...getParamsObj },
  { error, success },
  { error: ERROR, loading: LOADING, responder: SIGNUP }
);

export const register = (code, queryString, success, error) => Helpers.api(
  `/signon/next/${code}${queryString}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: REGISTER }
);
