import React from 'react';

const BlogCard = props => {
  const {
    blogCardImage, blogCardBody, blogCardDate, blogCardTitle, blogCardTag, active,
  } = props;
  const blogCardExcerpt = blogCardBody.slice(0, 300);

  if (active) {
    return (
      <div id="blog-card">
        {blogCardImage && (
          <div className="blog-article-image">
            <img src={blogCardImage} alt="author" />
          </div>
        )}

        {blogCardBody && (
        <div className="blog-article-body content-text">
          {blogCardBody}
        </div>
        )}
      </div>
    );
  }
  return (
    <div id="blog-card">
      {blogCardImage && (
        <div className="blog-card-image">
          <img src={blogCardImage} alt="author" />
        </div>
      )}

      <div className="blog-card-body">

        <div className="blog-card-tag-date">
          {blogCardTag && (
          <div className="blog-card-tag content-text">
            {blogCardTag}
          </div>
          )}

          {blogCardDate && (
          <div className="blog-card-date content-text">
            {blogCardDate}
          </div>
          )}
        </div>

        {blogCardTitle && (
          <div className="blog-card-title heading-text">
            {blogCardTitle}
          </div>
        )}

        {blogCardBody && (
          <div className="blog-card-excerpt content-text">
            {`${blogCardExcerpt}...`}
          </div>
        )}

        <div className="blog-card-more content-text">
          <u><i className="content-text">Read More</i></u>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
