import React from 'react';
import { FiBell } from 'react-icons/fi';

import Tabs from './Tabs';
import Pagination from './Pagination';
import withNavigation from './withNavigation';
import * as Helpers from '../helpers';
import NotificationLoader from './NotificationLoader';

const items = ['all', 'loan', 'repayment', 'withdrawal'];
// limit is not used properly in this context
const limit = 10;

class Notification extends React.Component {
  state = {
    activeTab: 0,
    hasUnread: false,

    loading: false,

    // To track unread notifications
    notificationType: 'all',
    notifications: {
      all: [],
      loan: [],
      repayment: [],
      withdrawal: [],
    },
    show: false,
    totalNotifications: 0,
  };

  dropdownRef = React.createRef();

  componentDidMount() {
    const { activeTab } = this.state;
    document.addEventListener('click', this.handleGlobalClick);
    this.fetchNotifications(limit, 0, items[activeTab]);
  }

  componentDidUpdate(prevProps, prevState) {
    // call get notification when active tab changes
    const { activeTab } = this.state;
    if (prevState.activeTab !== activeTab) {
      this.fetchNotifications(limit, 0, items[activeTab]);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  handleGlobalClick = event => {
    const dropdownNode = this.dropdownRef.current;
    if (dropdownNode && !dropdownNode.contains(event.target)) {
      this.setState({ show: false });
    }
  };

  handleDropdownToggle = event => {
    event.stopPropagation();
    this.setState(
      prevState => ({ show: !prevState.show }),
      () => {
        const { hasUnread } = this.state; // Destructure hasUnread from state
        if (hasUnread) {
          this.updateNotifications();
        }
      }
    );
  };

  fetchNotifications = (itemLimit = limit, offset = 0, type) => {
    const { getNotifications } = this.props;
    this.setState({ loading: true });
    getNotifications(itemLimit, offset, type, res => {
      const notifications = this.groupNotifications(res.data.all);
      const hasUnread = notifications.all.some(notif => notif.unread === 1);
      // total should be a value sent from db, not count of fetched items
      this.setState({
        hasUnread,
        notifications,
        totalNotifications: res.data.total,
      });
    });
    this.setState({ loading: false });
  };

  updateNotifications = () => {
    const { markNotificationsAsRead } = this.props;
    markNotificationsAsRead(() => {
      this.setState({ hasUnread: false });
    });
  };

  groupNotifications = notifications => {
    const grouped = {
      all: notifications,
      loan: notifications.filter(notif => notif.notification === 'loan'),
      repayment: notifications.filter(
        notif => notif.notification === 'repayment'
      ),
      withdrawal: notifications.filter(
        notif => notif.notification === 'withdrawal'
      ),
    };
    return grouped;
  };

  handleClearNotification = () => {
    const { markNotificationsAsDeleted } = this.props;
    markNotificationsAsDeleted(() => {
      Helpers.notification.success('Notification cleared!');
    });
    this.setState({
      notifications: {
        all: [],
        loan: [],
        repayment: [],
        withdrawal: [],
      },
    });
  };

  handlePagination = selectedPage => {
    const { notificationType } = this.state;
    const offset = selectedPage * limit;
    this.fetchNotifications(limit, offset, notificationType);
  };

  render() {
    const {
      show, notifications, hasUnread, activeTab, totalNotifications, loading,
    } = this.state;
    const { size, history } = this.props;
    const total = notifications[items[activeTab]].length || 0;

    return (
      <div ref={this.dropdownRef}>
        {size === 'big' ? (
          <>
            <div
              style={{ cursor: 'pointer', position: 'relative' }}
              onClick={this.handleDropdownToggle}
            >
              <FiBell style={{ height: '90px', width: '20px' }} />
              {hasUnread && <span className="notificationDot" />}
            </div>

            {show && (
              <div
                onClick={e => e.stopPropagation()}
                className="modal-container"
              >
                <div className="notification-center">Notifications Centre</div>

                <Tabs
                  current={num => this.setState({ activeTab: num })}
                  tabs={[
                    {
                      component: (
                        <NotificationList
                          data={notifications.all}
                          clearNotifcation={this.handleClearNotification}
                          loading={loading}
                        />
                      ),
                      key: 'all-notifications',
                      title: 'All',
                    },
                    {
                      component: (
                        <NotificationList
                          data={notifications.all}
                          clearNotifcation={this.handleClearNotification}
                          loading={loading}
                        />
                      ),
                      key: 'loans-notifications',
                      title: 'Loans',
                    },
                    {
                      component: (
                        <NotificationList
                          data={notifications.all}
                          clearNotifcation={this.handleClearNotification}
                          loading={loading}
                        />
                      ),
                      key: 'repayments-notifications',
                      title: 'Repayments',
                    },
                    {
                      component: (
                        <NotificationList
                          data={notifications.all}
                          clearNotifcation={this.handleClearNotification}
                          loading={loading}
                        />
                      ),
                      key: 'withdrawals-notifications',
                      title: 'Withdrawals',
                    },
                  ]}
                />

                {totalNotifications > limit && (
                  <div className="notificationList-pagination-box">
                    <Pagination
                      total={total}
                      perPage={limit}
                      handlePagination={this.handlePagination}
                      current={0}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <div style={{ cursor: 'pointer', position: 'relative' }}>
            <FiBell
              style={{
                cursor: 'pointer',
                height: '90px',
                marginRight: '10px',
                width: '20px',
              }}
              onClick={() => history.push('/notifications')}
            />
            {hasUnread && <span className="notificationDotMobile" />}
          </div>
        )}
      </div>
    );
  }
}

const NotificationList = ({ data, clearNotifcation, loading }) => {
  if (!data || data.length === 0) {
    return (
      <div className="notificationList-empty">No notifications available</div>
    );
  }

  const isValidUrl = url => {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <ul className="notificationList">
      {loading ? (
        <>
          <NotificationLoader />
          <NotificationLoader />
          <NotificationLoader />
        </>
      ) : (
        data.map((notif, index) => (
          <li key={index}>
            <div className="notificationList-img">
              {isValidUrl(notif.file_name) ? (
                <img src={notif.file_name} alt="Notification" />
              ) : (
                <FiBell size={20} />
              )}
            </div>
            <div className="notificationContent">
              <div className="notificationContentHeader">
                <h6
                  className={`notificationContentSubject ${
                    notif.unread === 1 ? 'bold' : ''
                  }`}
                >
                  {notif.subject}
                </h6>
                <h6 className="notificationContentDate">
                  {new Intl.DateTimeFormat('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    weekday: 'long',
                  }).format(new Date(notif.date))}
                </h6>
              </div>
              <div className="notificationContentBody">{notif.body}</div>
            </div>
          </li>
        ))
      )}
      {data?.length > 0 && (
        <div className="notificationFooter">
          <h3 className="notificationClear" onClick={clearNotifcation}>
            Clear all
          </h3>
        </div>
      )}
    </ul>
  );
};

export default withNavigation(Notification);
