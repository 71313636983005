import BigNumber from 'bignumber.js';

const Math = {
  add: (x, y) => new BigNumber(x).plus(y).toNumber(),
  divide: (x, y) => new BigNumber(x).div(y).toNumber(),
  multiply: (x, y) => new BigNumber(x).times(y).toNumber(),
  subtract: (x, y) => new BigNumber(x).minus(y).toNumber(),
  toDecimal: value => new BigNumber(value).toNumber(),
};
export default Math;
