import {
  FETCH_ALL_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS
} from '../actions';

const initialState = {
  all: [],
  limit: 10,
  loading: false,
  total: 0,
};

export default function (state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
  case FETCH_ALL_NOTIFICATIONS:
    return {
      ...state,
      all: payload.data.notifications,
      loading: false,
      total: payload.data.total,
    };

  case UPDATE_NOTIFICATIONS:
    return {
      ...state,
    };

  default:
    return state;
  }
}
