import React, { Component } from 'react';
import PropTypes from 'prop-types';

import loaderImage from '../assets/images/button-loader.gif';

class Button extends Component {
  render() {
    const {
      children, onClick, disabled, loading, size, background, cta,
    } = this.props;
    if (cta) {
      return (
        <button
          className={`cta-button ${loading ? 'loadable' : ''}`}
          onClick={onClick}
          disabled={loading || disabled}
        >
          {loading ? <img src={loaderImage} alt="Loading" style={{ height: '100px', marginTop: '-31px', width: '100px' }} /> : children}
        </button>
      );
    }
    return (
      <button
        disabled={loading || disabled}
        className={`typography button ${size} ${background || ''}`}
        onClick={onClick}
      >
        {loading ? <img src={loaderImage} alt="Loading" style={{ height: '120%', width: 'auto' }} /> : children}
      </button>
    );
  }
}

Button.defaultProps = {
  disabled: false,
};

Button.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'small', '']),
};

export default Button;
