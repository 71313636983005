/* eslint-disable no-lone-blocks */
import React, { useRef, useEffect, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(...registerables);

const options = {
  aspectRatio: 1.3,
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
};

// eslint-disable-next-line no-unused-vars
function createGradient(ctx, area) {
  const gradient = ctx.createLinearGradient(0, 0, 600, 0);
  gradient.addColorStop(0, '#87AB86');
  gradient.addColorStop(0.5, '#FFB778');
  gradient.addColorStop(1, '#FF9F69');
  return gradient;
}

const Graph = chartValues => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [values, setValues] = useState({ ...chartValues.chartValues });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData1 = {
      datasets: [{
        backgroundColor: [createGradient(chart.ctx, chart.chartArea), 'rgba(0, 0, 0, 0.1)'],
        borderRadius: [
          {
            innerEnd: 15,
            innerStart: 15,
            outerEnd: 15,
            outerStart: 15,
          },
          {
            innerEnd: 15,
            innerStart: 15,
            outerEnd: 15,
            outerStart: 15,
          },
        ],
        borderWidth: 0,
        circumference: 308,
        cutout: '80%',
        data: [values.a, values.b],
        label: 'portfolio graph',
        rotation: 206,
      }],
    };

    setChartData(chartData1);
    setValues({ ...chartValues.chartValues });
  }, [chartValues, values.a, values.b]);

  return <Chart ref={chartRef} type="doughnut" data={chartData} options={options} />;
};

export default Graph;

// dashboard graph code
// chartValues: { a: 0, b: 100 },
// const debt = parseInt(info?.debt?.current, 10);
// const amount = parseInt(portfolio.amount, 10);
// const debtPercent = Helpers.math.multiply(Helpers.math.divide(debt, Helpers.math.add(debt, amount)), 100);
// const amountPercent = Helpers.math.subtract(100, debtPercent);
// this.setState({ chartValues: { a: debtPercent || 0, b: amountPercent || 100 } });
{ /* <div id="dashboard-cards-lower-portfolio">
<div className="graph-text" style={{ margin: '20px 21px' }}>
  Your Portfolio
</div>
<hr style={{ margin: '0 21px' }} />
<div style={{ marginTop: '20px' }}>
  <Graph chartValues={chartValues} />
</div>
<div className="graph-text graph-abs">
  Available Credit
  <p>
    $
    {Helpers.formatMoney(Helpers.math.divide(credit, 100))}
  </p>
</div>

<div className="graph-label">
  {/* <div className="graph-dot credit" />
  Principal
  <div className="graph-dot balance" />
  Available Credit
</div>
</div> */ }
