import * as Helpers from '../../helpers';

import { LOADING, ERROR } from './user';

export const APPLICATION_GET_ALL = 'APPLICATION_GET_ALL';
export const APPLICATION_GET_USER = 'APPLICATION_GET_USER';
export const APPLICATION_CLEAR = 'APPLICATION_CLEAR';

export const getAllApplications = (success, error) => Helpers.api(
  '/application',
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: APPLICATION_GET_ALL }
);

export const getUserApplications = (user_id, success, error) => Helpers.api(
  `/application${user_id ? `?user_id=${user_id}` : ''}`,
  'GET',
  {},
  { error, success },
  { error: ERROR, loading: LOADING, responder: APPLICATION_GET_USER }
);

export const clearApplications = () => dispatch => {
  dispatch({ type: APPLICATION_CLEAR });
};
