import React from 'react';
import PaystackWithdrawal from './PaystackWithdrawal';

import {
  Keypad, Button, Page, Loading
} from '../../components';
import * as Helpers from '../../helpers';

import tickCircle from '../../assets/images/tick-circle-orange.svg';

class Withdrawal extends React.Component {
  state = {
    amount: 0,
    appId: 0,
    entryAppId: 0,
    firstLoad: true,
    show: {
      choice: false,
      keypad: false,
      selectVendor: false,
    },
  };

  componentDidMount() {
    document.body.classList.remove('no-overflow');
    document.title = 'Withdraw | Carrot';
    const {
      getProcessors, user: { user_id }, getUserApplications, application: { user: applications },
    } = this.props;
    getProcessors();
    if (applications.length === 0) {
      getUserApplications(user_id);
    }
    const application_id = Helpers.token.get('user:application_id');
    this.setState({ appId: Number(application_id), entryAppId: Number(application_id) });
  }

  componentDidUpdate() {
    const { history, user: { blacklist } } = this.props;
    if (blacklist?.signin || blacklist?.withdrawal) {
      Helpers.notification.error('You are not allowed to use this service.');
      history.push('/dashboard');
      return;
    }

    const { show, firstLoad } = this.state;
    const { getUserApplications, user: { user_id, card }, application: { user: applications } } = this.props;
    if (firstLoad && card === 1) {
      if (Number(Helpers.token.get('user:application_id')) > 0) {
        this.setState({
          show: {
            ...show,
            keypad: true,
          },
        });
      } else {
        this.setState({
          show: {
            ...show,
            selectVendor: true,
          },
        });
        if (applications.length === 0) {
          getUserApplications(user_id);
        }
      }
      this.setState({ firstLoad: false });
    }
  }

  show = (comp, option, value) => () => {
    const { show } = this.state;
    this.setState({
      [option]: value,
      show: {
        ...show,
        ...Object.keys(show).reduce((prev, cur) => ({ ...prev, [cur]: false }), {}),
        [comp]: true,
      },
    });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger,
    });
  }

  setAmount = amount => {
    this.setState({
      amount,
    });
  }

  setAppId = id => {
    const { appId } = this.state;
    const { user: { loading, user_id } } = this.props;
    const loadingApplicationOrUser = loading.some(url => url === `/application${user_id ? `?user_id=${user_id}` : ''}`) || loading.some(url => url === `/user?application_id=${appId}`);
    if (!loadingApplicationOrUser) {
      this.setState({ appId: id });
      Helpers.token.set(id, 'user:application_id');
      const { history, portfolio: { all: portfolioArray } } = this.props;
      const application_id = id;
      const waitlist = Number(application_id) === 0
        ? '0'
        : portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0]?.waitlist;
      if (waitlist === '1') {
        history.push('/waitlist');
      } else {
        const { load } = this.props;
        load(
          () => {},
          () => {},
          null,
          id
        );
      }
    }
  }

  render() {
    const {
      amount, show, appId, entryAppId,
    } = this.state;
    const {
      user: {
        info, card, user_id, loading,
      },
      application: { user: applications },
    } = this.props;

    if (show.selectVendor) {
      return (
        <Page
          title="Offer"
          alignContentMobile="align-left"
          back={this.back()}
          background="illustration-2"
          hasHeader
          hasLogo
          hm="100px 0 0 0"
          lm="53px 0 0 0"
          footer={<></>}
        >
          <div className="heading vendor">
            Select Vendor to Withdraw From
          </div>

          <div className="subheading vendor">
            We are consistently working on adding more fintech firms to this list
          </div>

          <div className="vendor-cards">
            {applications.map((vendor, i) => (
              <button key={i} className="item highlight-light" onClick={() => this.setAppId(vendor.application_id)}>
                <img src={/^http/.test(vendor.images.logo) ? vendor.images.logo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.logo)}`} alt="" className="vendor-logo" />
                {appId === vendor.application_id && <img className="tick" src={tickCircle} alt="" />}
              </button>
            ))}
          </div>

          <div className="vendor-button">
            <Button
              disabled={appId === 0}
              loading={loading.some(url => url === `/application${user_id ? `?user_id=${user_id}` : ''}`) || loading.some(url => url === `/user?application_id=${appId}`)}
              onClick={this.show('keypad')}
              size="large"
              background="light"
            >
              WITHDRAW NOW
            </Button>
          </div>
        </Page>
      );
    }

    if (show.keypad) {
      return (
        <Keypad
          minimum={Number(process.env.REACT_APP_MINIMUM_AMOUNT) / 100}
          back={appId === 0 || entryAppId === 0 ? this.show('selectVendor') : this.back()}
          background="illustration-3"
          heading="How much do you want to withdraw?"
          next={this.show('choice')}
          amount={Helpers.math.divide(info.balance, 100)}
          setAmount={this.setAmount}
          buttonText="WITHDRAW"
          buttonDisabled={!amount > 0}
          appLogo={applications?.filter(vendor => Number(vendor.application_id) === Number(Helpers.token.get('user:application_id')))[0]?.images.logo}
        />
      );
    }

    if (show.choice) {
      return <PaystackWithdrawal {...this.props} amount={Helpers.math.multiply(amount, 100)} back={this.show('keypad')} />;
    }

    if (card === null) {
      return <Loading size="big" />;
    }

    return (
      <Page
        title="Add Card"
        alignContentMobile="align-left"
        back={this.back()}
        background="illustration-1"
        hasHeader
        hasLogo
        hm="132px 0 0 0"
        lm="68px 0 0 0"
        footer={<></>}
      >
        <div className="heading addCard">
          Please enter your card details for easy repayments
        </div>

        <div>
          <Button onClick={this.back('add-card')} size="large" background="light">ADD CARD</Button>
        </div>
      </Page>
    );
  }
}

export default Withdrawal;
