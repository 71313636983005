// src/components/NotificationsPage.js
import React from 'react';
import { FiBell } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import NotificationLoader from './NotificationLoader';
import Tabs from './Tabs';
import Pagination from './Pagination';
import Rates from './Rates';
import arrowLeft from '../assets/images/arrow-left.svg';
import * as Helpers from '../helpers';

const items = ['all', 'loan', 'repayment', 'withdrawal'];
// limit is not used properly in this context
const limit = 10;
class NotificationsPage extends React.Component {
  state = {
    activeTab: 0,
    loading: false,
    notificationType: 'all',
    notifications: {
      all: [],
      loan: [],
      repayment: [],
      withdrawal: [],
    },
    totalNotifications: 0,
  };

  componentDidMount() {
    const { activeTab } = this.state;
    this.fetchNotifications(limit, 0, items[activeTab]);
  }

  componentDidUpdate(prevProps, prevState) {
    // call get notification when active tab changes
    const { activeTab } = this.state;
    if (prevState.activeTab !== activeTab) {
      this.fetchNotifications(limit, 0, items[activeTab]);
    }
  }

  fetchNotifications = (itemLimit = limit, offset = 0, type) => {
    const { getNotifications } = this.props;
    // total should be a value sent from db, not count of fetched items
    this.setState({ loading: true });
    getNotifications(itemLimit, offset, type, res => {
      this.setState({
        notifications: this.groupNotifications(res.data.all),
        totalNotifications: res.data.total,
      });
    });
    this.setState({ loading: false });
  };

  groupNotifications = notifications => {
    const grouped = {
      all: notifications,
      loan: notifications.filter(notif => notif.notification === 'loan'),
      repayment: notifications.filter(
        notif => notif.notification === 'repayment'
      ),
      withdrawal: notifications.filter(
        notif => notif.notification === 'withdrawal'
      ),
    };
    return grouped;
  };

  handlePagination = selectedPage => {
    const { notificationType } = this.state;
    const offset = selectedPage * limit;
    this.fetchNotifications(limit, offset, notificationType);
  };

  handleClearNotification = () => {
    const { markNotificationsAsDeleted } = this.props;
    markNotificationsAsDeleted(() => {
      Helpers.notification.success('Notification cleared!');
    });
    this.setState({
      notifications: {
        all: [],
        loans: [],
        repayments: [],
        withdrawals: [],
      },
    });
  }

  render() {
    const {
      notifications, activeTab, totalNotifications, loading,
    } = this.state;
    const { windowWidth, userData } = this.props;
    const total = notifications[items[activeTab]].length || 0;
    return (
      <>
        <Rates windowWidth={windowWidth} userData={userData} />

        <div id="notification-center-mobile-view">
          <div id="notification-center-mobile-view-header">
            <div style={{ flex: '0 0 auto' }}>
              <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                <img
                  src={arrowLeft}
                  className="header-back-image"
                  alt="back"
                  onClick={this.back}
                />
              </Link>
            </div>
            <div className="notification-center">Notifications Centre</div>
            <div />
          </div>

          <Tabs
            current={num => this.setState({ activeTab: num })}
            tabs={[
              {
                component: <NotificationList data={notifications.all} handleClearNotification={this.handleClearNotification} loading={loading} />,
                key: 'all-notifications-page',
                title: 'All',
              },
              {
                component: <NotificationList data={notifications.loan} handleClearNotification={this.handleClearNotification} loading={loading} />,
                key: 'loans-notifications-page',
                title: 'Loans',
              },
              {
                component: <NotificationList data={notifications.repayment} handleClearNotification={this.handleClearNotification} loading={loading} />,
                key: 'repayments-notifications-page',
                title: 'Repayments',
              },
              {
                component: (
                  <NotificationList data={notifications.withdrawal} handleClearNotification={this.handleClearNotification} loading={loading} />
                ),
                key: 'withdrawals-notifications-page',
                title: 'Withdrawals',
              },
            ]}
          />
          {(totalNotifications > limit) && (
            <div className="notificationList-pagination-box">
              <Pagination
                total={total}
                perPage={limit}
                handlePagination={this.handlePagination}
                current={0}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const NotificationList = ({ data, handleClearNotification, loading }) => {
  if (!data || data.length === 0) {
    return <div className="notificationList-empty">No notifications available</div>;
  }

  const isValidUrl = url => {
    try {
      // eslint-disable-next-line no-new
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <ul className="notificationList">
      {
        loading
          ? (
            <>
              <NotificationLoader />
              <NotificationLoader />
              <NotificationLoader />
            </>
          )
          : data.map((notif, index) => (
            <li key={index}>
              {/* Round Circle Image (Placeholder) */}
              <div className="notificationList-img">
                {isValidUrl(notif.file_name) && notif.file_name ? (
                  <img src={notif.file_name} alt="Notification" />
                ) : (
                  <FiBell size={20} />
                )}
              </div>

              {/* Notification Content */}
              <div className="notificationContent">
                <div className="notificationContentHeader">
                  <div className={`notificationContentSubject ${notif.unread === 1 ? 'bold' : ''}`}>{notif.subject}</div>
                  <div className="notificationContentDate">
                    {new Intl.DateTimeFormat('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      weekday: 'long',
                    }).format(new Date(notif.date))}
                  </div>
                </div>
                <div className="notificationContentBody">{notif.body}</div>
              </div>
            </li>
          ))
      }
      {/* Notification Footer */}
      <div className="notificationFooter">
        <h3
          className="notificationClear"
          onClick={handleClearNotification}
        >
          Clear all
        </h3>
      </div>
    </ul>
  );
};

export default NotificationsPage;
