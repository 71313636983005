import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'qs';

import * as actions from '../store/actions';
import Loading from './Loading';
import * as Helpers from '../helpers';

class PrivateRoutes extends Component {
  componentDidMount() {
    if (Helpers.token.get('user:token') && window.hj && window.groove.widget) {
      const {
        user: {
          email, firstname, lastname, user_id,
        },
      } = this.props;

      // Set HotJar details
      window.hj('identify', user_id, {
        environment: process.env.REACT_APP_NODE_ENV,
        referrer: document.referrer,
      });

      // Set Groove details
      window.groove.widget.identifyContact('contact_email', email);
      window.groove.widget.setContact({
        contact_first_name: firstname,
        contact_last_name: lastname,
        contact_user_id: user_id,
      });
    }
    const queryString = qs.parse(window.location.search.slice(1));
    if (Helpers.token.get('user:token') && queryString && queryString.redirect) {
      window.location = queryString.redirect;
    }

    if (Helpers.token.get('user:token')) {
      const {
        load, getPortfolio, user: { user_id }, portfolio: { all: portfolioArray },
      } = this.props;
      if (user_id === 0) {
        load(
          () => {},
          () => {},
          null,
          0
        );
      }
      if (portfolioArray.length <= 0) {
        getPortfolio(0);
      }
    }
  }

  render() {
    const queryString = window.location.search ? window.location.search : '';
    const { portfolio: { all: portfolioArray } } = this.props;
    const application_id = Helpers.token.get('user:application_id');
    const waitlist = Number(application_id) === 0
      ? '0'
      : portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0]?.waitlist;

    return (
      Helpers.token.get('user:token')
        ? !waitlist
          ? <Loading size="big" />
          : waitlist === '1' && window.location.pathname !== '/waitlist'
            ? <Redirect to="/waitlist" />
            : <Route {...this.props} />
        : <Redirect to={`/signin${queryString}`} />
    );
  }
}

export default connect(state => state, actions)(PrivateRoutes);
