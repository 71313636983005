import React from 'react';
import { Link } from 'react-router-dom';
import { countries } from 'countries-list';
import libphonenumber from 'google-libphonenumber';

import {
  Button, Page, Input, Tabs, DoubleInput
} from '../../components';
import ssl from '../../assets/images/ssl.svg';
import withText from '../../assets/images/with.svg';
import tickCircle from '../../assets/images/tick-circle-orange.svg';
import logo from '../../assets/images/carrot-logo-orange.png';
// import logo from '../../assets/images/carrot-logo-orange-xmas.png'; // xmas

import * as Helpers from '../../helpers';

const countriesKeys = Object.keys(countries);
class Signon extends React.Component {
  state = {
    activeTab: 0,
    appName: '',
    application_id: 0,
    callingCode: '234',
    countryCode: 'NG',
    email: '',
    mobile: '',
    otp: '',
    otpTime: -1,
    show: 'selectVendor',
  };

  secure = () => {
    const { activeTab } = this.state;
    return (
      <>
        <img src={ssl} alt="SSL" />
        {' '}
        By clicking &quot;
        REQUEST
        {' '}
        {activeTab === 0 ? 'OTP' : 'LINK'}
        &quot;, you acknowledge that you have read, understood, and agree to Carrot&apos;s
        {' '}
        <br />
        <Link style={{ color: '#FE5000' }} to="/user-agreement" target="_blank">User Agreement</Link>
        {' '}
        and
        {' '}
        <Link style={{ color: '#FE5000' }} to="/privacy-policy" target="_blank">Privacy Policy</Link>
        .
      </>
    );
  }

  componentDidMount = () => {
    const { location, getAllApplications, application: { all: applications } } = this.props;
    const { callingCode } = this.state;
    if (location.query) {
      const { application_id, mobile } = location.query;
      if (application_id && mobile) {
        this.setState({ application_id, mobile });
        this.setShow('otpInput');
        const { otp } = this.props;
        otp(
          `+${callingCode}${mobile}`,
          'mobile',
          () => {
            this.startOtpTimer();
            Helpers.notification.success('Your OTP has been sent.', 'Sent!');
          },
          () => {}
        );
      }
    }

    if (applications.length === 0) {
      getAllApplications();
    }
  };

  change = type => e => this.setState({ [type]: e.target.value });

  setShow = choice => {
    this.setState({ show: choice });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/signin', {
      trigger,
    });
  }

  setApplicationId = (id, name) => {
    this.setState({ appName: name, application_id: id });
    Helpers.token.set(id, 'user:application_id');
  }

  startOtpTimer = () => {
    this.setState({ otpTime: 180 });
    this.interval = setInterval(() => {
      const { otpTime } = this.state;
      if (otpTime <= 1) {
        this.stopOtpTimer();
      } else {
        this.setState({ otpTime: (otpTime - 1) });
      }
    }, 1000);
  }

  stopOtpTimer = () => {
    this.setState({ otpTime: 0 });
    clearInterval(this.interval);
  }

  handleRequestLink = () => {
    const {
      callingCode, email, mobile, activeTab,
    } = this.state;
    const emailOrMobile = activeTab === 0 ? mobile : email;
    const emailOrMobileTrim = emailOrMobile.trim();
    if (!emailOrMobileTrim) {
      Helpers.notification.error(`Enter your ${activeTab === 0 ? 'mobile number' : 'email'} to continue`);
    } else if (activeTab === 0) {
      const finalEmailOrMobile = `+${callingCode}${emailOrMobileTrim}`;
      const validateMobile = this.validatePhoneNumber(finalEmailOrMobile);
      if (validateMobile) {
        const { otp } = this.props;
        otp(
          finalEmailOrMobile,
          'mobile',
          () => {
            this.setShow('otpInput');
            this.startOtpTimer();
            Helpers.notification.success('Your OTP has been sent.', 'Sent!');
          },
          () => {}
        );
      } else {
        Helpers.notification.error('Incorrect mobile number format!');
      }
    } else {
      this.requestLink(emailOrMobileTrim, 'email');
    }
  }

  // Links should only be sent to emails
  requestLink = (emailOrMobile, signinType) => {
    const { application_id } = this.state;
    const { signon } = this.props;
    signon(
      application_id,
      emailOrMobile,
      signinType,
      null,
      response => {
        if (response && response.data && response.data.redirect) {
          Helpers.notification.success(response.message);
          setTimeout(() => {
            window.location = response.data.redirect;
          }, 1000);
        } else {
          Helpers.notification.success(response.message);
        }
      }
    );
  }

  setCountryCode = arg => {
    const countryCode = arg.target.value;
    const callingCode = countries[countryCode].phone;
    this.setState({ callingCode, countryCode });
  }

  validatePhoneNumber = mobile => {
    const { countryCode } = this.state;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const country = { code: countryCode };

    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      return true;
    }

    try {
      if (
        !phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(
            mobile,
            country.code
          ),
          country.code
        )
      ) {
        return false;
      }
    } catch (e) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return false;
    }
    return true;
  }

  otpSignin = () => {
    const { signon } = this.props;
    const {
      otp, email, mobile, callingCode, activeTab, application_id,
    } = this.state;
    if ((email || mobile) && otp) {
      signon(
        application_id,
        activeTab === 0 ? `+${callingCode}${mobile.trim()}` : email.trim(),
        activeTab === 0 ? 'mobile' : 'email',
        otp,
        response => {
          if (response && response.data && response.data.redirect) {
            Helpers.notification.success(response.message);
            setTimeout(() => {
              window.location = response.data.redirect;
            }, 1000);
          } else {
            Helpers.notification.success(response.message);
          }
        },
        () => {}
      );
    } else {
      Helpers.notification.error('Fill in all fields to continue.', 'Incomplete data');
    }
  }

  validateOTP = type => e => {
    const { otp } = this.state;
    if (type === 'change') {
      this.setState({ otp: e.target.value.substring(0, 6).trim() });
      if (e.target.value.length > 6) {
        Helpers.notification.info('OTP cannot have more than 6 digits.');
      }
    } else if (otp.length < 5) {
      Helpers.notification.info('OTP cannot have less than 5 digits.', 'OTP too short!');
    } else {
      this.otpSignin();
    }
  }

  render() {
    const {
      email, mobile, show, application_id, countryCode, appName, activeTab, otp, otpTime, callingCode,
    } = this.state;
    const { user: { loading }, application: { all: applications } } = this.props;
    const appLogo = applications?.filter(vendor => vendor.application_id === application_id)[0]?.images.logo;

    switch (show) {
    case 'selectVendor':
      return (
        <Page
          title="Signon"
          alignContentMobile="align-left"
          back={this.back()}
          background="illustration-2"
          hasHeader
          hasLogo
          hm="100px 0 0 0"
          lm="53px 0 0 0"
          footer={<></>}
        >
          <div className="heading vendor">
            To use one-time signin, select a Partner
          </div>

          <div className="subheading vendor">
            We are consistently working on adding more fintech firms to this list.
          </div>

          <div className="vendor-cards">
            {applications.map((vendor, i) => (
              <button key={i} className="item highlight-light" onClick={() => this.setApplicationId(vendor.application_id, vendor.name)}>
                <img src={/^http/.test(vendor.images.logo) ? vendor.images.logo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.logo)}`} alt="" className="vendor-logo" />
                {application_id === vendor.application_id && <img className="tick" src={tickCircle} alt="" />}
              </button>
            ))}
          </div>

          <div className="vendor-button">
            <Button
              disabled={application_id === 0}
              onClick={() => this.setShow('partnerAuth')}
              size="large"
              background="light"
            >
              SUBMIT
            </Button>
          </div>

          <Link to="/signin">
            <div className="footer signin">
              I want to sign in with my password
            </div>
          </Link>
        </Page>
      );
    case 'partnerAuth':
      return (
        <Page
          title="Signon"
          alignContentMobile="align-left"
          back={() => this.setShow('selectVendor')}
          background="illustration-2"
          hasHeader
          hm="70px 0 0 0"
          footer={<></>}
        >
          <div className="partnerAuth-logo">
            <img src={logo} alt="Carrot Logo" className="carrot" />
            <img src={withText} alt="" className="with" />
            <img src={/^http/.test(appLogo) ? appLogo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(appLogo)}`} alt="" className="partner" width="75px" />
          </div>

          <div className="heading partnerAuth">
            Use one-time signin
          </div>

          <div className="subheading partnerAuth">
            We will need the
            {' '}
            {activeTab === 0 ? 'mobile number' : 'email'}
            {' '}
            used in creating your
            {' '}
            <b>{Helpers.capitalizeFirstLetter3(appName)}</b>
            {' '}
            account
            {' '}
            {activeTab === 0 ? 'to send you a signin OTP.' : 'to send you a signin link.'}
          </div>

          <Tabs
            current={num => this.setState({ activeTab: num })}
            tabs={[
              {
                component: (<div key={1} />),
                title: 'Phone Number',
              },
              {
                component: (<div key={2} />),
                title: 'Email',
              },
            ]}
          />

          {activeTab === 0 ? (
            <DoubleInput
              otherClasses="signin-input"
              leftInput={(
                <div className="mobile_code_select">
                  <Input
                    placeholder="Mobile"
                    type="select"
                    name="countryCode"
                    id="country_code_select"
                    onChange={this.setCountryCode}
                    value={countryCode}
                    options={[
                      { label: 'NG (+234)', value: 'NG' }, { label: 'US (+1)', value: 'US' },
                    ].concat(countriesKeys.map(country => ({
                      label: `${country} (+${countries[country].phone})`,
                      value: country,
                    })))}
                  />
                </div>
              )}
              rightInput={
                <Input placeholder="" onChange={this.change('mobile')} value={mobile} type="number" required />
              }
            />
          ) : (
            <div className="signin-input">
              <Input placeholder="Email" type="text" onChange={this.change('email')} value={email} />
            </div>
          )}

          <div className="signup-button">
            <Button loading={loading.some(url => url === (activeTab === 0 ? '/user/otp' : '/signin/link'))} onClick={this.handleRequestLink} size="large" background="light">
              REQUEST
              {' '}
              {activeTab === 0 ? 'OTP' : 'LINK'}
            </Button>
          </div>

          <div className="footer signup">
            {this.secure()}
          </div>

          <Link to="/signin">
            <div className="footer signin">
              I want to sign in with my password
            </div>
          </Link>
        </Page>
      );
    case 'otpInput':
      return (
        <Page
          title="Signon"
          alignContentMobile="align-left"
          back={() => {
            this.setShow('partnerAuth');
            this.setState({
              otp: '',
            });
          }}
          background="illustration-1"
          hasHeader
          hasLogo
          hm="130px 0 0 0"
          lm="50px 0 0 0"
          footer={<></>}
        >
          <div className="heading forgot">Enter OTP</div>
          <div className="subheading forgot">
            We
            {' '}
            {loading.some(url => (url === '/user/otp')) ? 'are in the process of sending' : 'have sent'}
            {' '}
            a One Time Password (OTP) to your mobile number:
            {' '}
            {`+${callingCode}${mobile.slice(0, 4)}*****${mobile.slice(-3)}`}
            . Please input it here.
          </div>

          <div className="forgot-input">
            <Input placeholder="OTP" type="number" required onChange={this.validateOTP('change')} value={otp} autocomplete="new-password" />
          </div>
          { (otpTime >= 1 && otpTime <= 180) && (
            <div className="forgot-otp-resend">
              <p>Resend in</p>
                &nbsp;
              {otpTime}
              {' '}
              seconds
            </div>
          )}

          { otpTime === 0 && (
            <div className="resend-otp-div">
              <div className="resend-otp-button" onClick={this.sendOtp}>
                Resend OTP
              </div>
            </div>
          )}

          <div className="forgot-button">
            <Button disabled={otp === ''} size="large" loading={loading.some(url => (url === '/signin/link') || (url === '/user/otp'))} onClick={this.validateOTP('submit')} background="light">SUBMIT</Button>
          </div>
        </Page>
      );
    default:
      return null;
    }
  }
}

export default Signon;
