// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from 'react-icons/fi';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { BiRefresh } from 'react-icons/bi';

import * as Helpers from '../helpers';

class CurrentBalanceCard extends React.Component {
  state = {
    hideBalance: false,
  }

  handleRefresh = () => {
    const { refresh } = this.props;
    refresh();
  }

  handleHideBalance = () => {
    const { hideBalance } = this.state;
    this.setState({ hideBalance: !hideBalance });
  }

  render() {
    const { hideBalance } = this.state;
    const {
      info, app, loading, application_id,
    } = this.props;

    return (
      <div id="dashboard-card-upper-balance" style={{ backgroundColor: app.backgroundColor }} title={`$${Helpers.formatMoney(Helpers.math.divide(info.balance, 100) || 0)}`}>
        <div className="text-line">
          <div className="text-line-top">
            <div className={`text text-${app.fontColor}`}>
              Your Total Current Balance on
            </div>
            <div className="">
              {hideBalance
                ? (
                  <BsEyeSlash className={`icon-img-slash icon-${app.fontColor}`} onClick={this.handleHideBalance} />
                )
                : (
                  <BsEye className={`icon-img-eye icon-${app.fontColor}`} onClick={this.handleHideBalance} />
                )}
            </div>
          </div>

          <div className={`text text-line-bottom text-${app.fontColor}`}>
            <b>{app.name}</b>
            {' '}
            is
          </div>
        </div>

        <div className="value-line-container">
          <div className="value-line">
            {
            hideBalance || loading.some(url => url === '/user' || url === `/user?application_id=${application_id}`)
              ? (
                <div className={`value text-${app.fontColor}`}>
                  *****
                </div>
              )
              : (
                <>
                  <div className={`value text-${app.fontColor}`}>
                    $
                    {Helpers.formatMoney(Helpers.math.divide(info.balance, 100) || 0)}
                  </div>
                  <div className="value-icon">
                    <OverlayTrigger
                      placement="right"
                      overlay={(
                        <Tooltip id={`tooltip-${'right'}`} className="tooltip-right2">
                          This is your withdrawable wallet balance
                        </Tooltip>
                          )}
                    >
                      <div>
                        {app.backgroundColor && (
                          <FiInfo className={`dashboard-info-icon icon-${app.fontColor}`} />
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                </>
              )
            }
          </div>

          <div className="dashboard-refresh-button-small mobile-display-block web-display-none">
            <BiRefresh
              className={`icon zls${loading.some(url => url === `/user?application_id=${application_id}` || url === `/portfolio?application_id=${application_id}`)
                ? ' loading' : ''} icon-${app.fontColor}`}
              onClick={this.handleRefresh}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentBalanceCard;
