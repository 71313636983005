import React from 'react';

const BlogHeader = props => {
  const {
    topic, authorImage, authorName, authorRole,
  } = props;
  return (
    <section id="blog-title-section">
      <div className="heading-2-text">
        {topic || 'Carrot\'s Blog'}
      </div>

      {topic
        ? (
          <div className="author-details">
            <div className="author-image">
              {authorImage && <img src={authorImage} alt="author" />}
            </div>
            <div className="author-name-role">
              {authorName && (
                <div className="author-name content-text">
                  {authorName}
                </div>
              )}
              {authorRole && (
                <div className="author-role content-text">
                  {authorRole}
                </div>
              )}
            </div>
          </div>
        )
        : (
          <div className="content-text">
            The latest updates, musings and stories from the Carrot team
          </div>
        )}
    </section>
  );
};

export default BlogHeader;
