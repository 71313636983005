import { SnackbarProvider } from 'notistack';
import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import eruda from 'eruda';

import Routes from './routes';
import * as Helpers from './helpers';
import successImg from './assets/images/flash-success.svg';
import errorImg from './assets/images/flash-error.svg';
import infoImg from './assets/images/flash-info.svg';
import closeImg from './assets/images/flash-close.svg';

if (process.env.REACT_APP_NODE_ENV !== 'production') {
  eruda.init({
    autoScale: true,
    useShadowDom: true,
  });
}

if (process.env.REACT_APP_NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2e454b1e87a147faaa6578872e6271e3@o605468.ingest.sentry.io/5923744',
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

class Carrot extends React.Component {
  render() {
    return (
      <SnackbarProvider
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        ref={Helpers.notification.reference}
        action={key => <div onClick={() => Helpers.notification.close(key)}>DISMISS</div>}
        content={(key, obj) => {
          if (window.location.pathname === '/' || window.location.pathname === '/home/business' || window.location.pathname === '/home/individual') {
            return <div />;
          }
          let message = obj;
          let title = '';
          let variant = 'info';
          try {
            const notification = JSON.parse(obj);
            message = notification.message;
            title = notification.title;
            variant = notification.variant;
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Unable to parse notification object:', e);
          }
          return (
            <div className="notification" id={key}>
              <img
                className="notification-img"
                src={variant === 'error' ? errorImg : variant === 'success' ? successImg : infoImg}
                alt={Helpers.capitalizeFirstLetter3(variant)}
              />
              <div>
                <div className="notification-title">{title}</div>
                <span className="notification-content">{message}</span>
              </div>
              <img
                className="notification-close"
                src={closeImg}
                alt="Dismiss"
                onClick={() => Helpers.notification.close(key)}
              />

            </div>
          );
        }}
      >
        <Routes />
      </SnackbarProvider>
    );
  }
}

export default Carrot;
