// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { PaystackConsumer } from 'react-paystack';
import sha256 from 'sha256';
import Modal from 'react-bootstrap/Modal';
// import { CSVLink } from 'react-csv';
import Skeleton from 'react-loading-skeleton';
import Loans from './Tables/Loans';
import Repayments from './Tables/Repayments';
import Withdrawals from './Tables/Withdrawals';

import 'react-loading-skeleton/dist/skeleton.css';

import {
  Button, Tabs, Hamburger, Rates, Banner, UserIcon, ActionCardArray,
  DetailsCard, CurrentBalanceCard, ApplicationCard, MultiSelect, Pagination, Notification
} from '../../components';

import refreshIcon from '../../assets/images/refresh.svg';
// import hamburger from '../../assets/images/hamburger.svg';
import carrotWhiteLogoMark from '../../assets/images/carrot-logo-mark-white.png';

import * as Helpers from '../../helpers';

const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const allPortfolios = {
  application_id: 0,
  images: {
    backgroundColor: '#fe5000',
    icon: carrotWhiteLogoMark,
  },
  name: 'All Portfolios',
};

class Dashboard extends React.Component {
  state = {
    activeTab: 0,
    app: {
      backgroundColor: undefined,
      banner: undefined,
      fontColor: undefined,
      icon: undefined,
      name: undefined,
    },
    application_id: Number(Helpers.token.get('user:application_id')),
    currentMonth: '',
    firstLoad: true,
    futureMonth: '',
    menu: false,
    open: false,
    running: false,
    windowWidth: 0,
  };

  componentDidMount() {
    const { history: { location } } = this.props;

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    document.title = 'Dashboard | Carrot';
    this.refresh(true);
    this.setMonths();

    if (location.state && location.state.trigger === 'add-card') {
      this.toggle();
    }

    if (location.state && location.state.trigger === 'refresh') {
      this.refresh();
    }
  }

  componentDidUpdate() {
    const { firstLoad } = this.state;
    const { user: { user_id } } = this.props;
    if (firstLoad && Number(user_id) > 0) {
      this.setState({ firstLoad: false });
      const { getUserApplications, application: { user: applications } } = this.props;
      if (applications.length === 0) {
        getUserApplications(
          user_id,
          res => {
            const application = [allPortfolios, ...(res.data.applications)]?.filter(vendor => Number(vendor.application_id) === Number(Helpers.token.get('user:application_id')))[0];
            this.setState({
              app: {
                backgroundColor: application?.images.backgroundColor,
                banner: application?.images.banner,
                fontColor: (application?.images.backgroundColor).toLowerCase() === '#ffffff' ? 'black' : 'white',
                icon: application?.images.icon,
                name: application?.name,
              },
            });
          }
        );
      } else {
        const application = [allPortfolios, ...applications]?.filter(vendor => Number(vendor.application_id) === Number(Helpers.token.get('user:application_id')))[0];
        this.setState({
          app: {
            backgroundColor: application?.images.backgroundColor,
            banner: application?.images.banner,
            fontColor: (application?.images.backgroundColor).toLowerCase() === '#ffffff' ? 'black' : 'white',
            icon: application?.images.icon,
            name: application?.name,
          },
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  isNearBottom = el => el.scrollTop + el.clientHeight >= el.scrollHeight - 50;

  handlePagination = selectedPage => {
    const page = selectedPage.selected;
    const { activeTab, running, application_id } = this.state;
    const {
      getLoans, getRepayments, getWithdrawals,
      clearLoans, clearRepayments, clearWithdrawals,
      setLoanOffset, setRepaymentOffset, setWithdrawalOffset,
      user: { loading },
      loan: { table: { limit: loanLimit, offset: loanOffset } },
      repayment: { table: { limit: repaymentLimit, offset: repaymentOffset } },
      withdrawal: { table: { limit: withdrawalLimit, offset: withdrawalOffset } },
    } = this.props;

    if (
      running

      || loading.some(url => url === `/loan?limit=${loanLimit}&offset=${loanOffset}&application_id=${application_id}`)
      || loading.some(url => url === `/repayment?limit=${repaymentLimit}&offset=${repaymentOffset}&application_id=${application_id}`)
      || loading.some(url => url === `/withdrawal?limit=${withdrawalLimit}&offset=${withdrawalOffset}&application_id=${application_id}`)
    ) {
      return;
    }
    this.setState({ running: true });
    if (activeTab === 0) {
      setLoanOffset(loanLimit, Helpers.math.multiply((page || 0), loanLimit));
      clearLoans();
      getLoans(
        loanLimit,
        Helpers.math.multiply((page || 0), loanLimit),
        application_id,
        () => this.setState({ running: false })
      );
    } else if (activeTab === 1) {
      setRepaymentOffset(repaymentLimit, Helpers.math.multiply((page || 0), repaymentLimit));
      clearRepayments();
      getRepayments(
        repaymentLimit,
        Helpers.math.multiply((page || 0), repaymentLimit),
        application_id,
        () => this.setState({ running: false })
      );
    } else if (activeTab === 2) {
      setWithdrawalOffset(withdrawalLimit, Helpers.math.multiply((page || 0), withdrawalLimit));
      clearWithdrawals();
      getWithdrawals(
        withdrawalLimit,
        Helpers.math.multiply((page || 0), withdrawalLimit),
        application_id,
        () => this.setState({ running: false })
      );
    }
  }

  onScroll = () => {
    const divElement = document.getElementById('content');
    if (this.isNearBottom(divElement)) {
      const { activeTab, running, application_id } = this.state;
      const {
        getLoans,
        getRepayments,
        getWithdrawals,
        user: { loading },
        loan: {
          all: allLoans,
          table: { limit: loanLimit, offset: loanOffset },
        },
        repayment: {
          all: allRepayments,
          table: { limit: repaymentLimit, offset: repaymentOffset },
        },
        withdrawal: {
          all: allWithdrawals,
          table: { limit: withdrawalLimit, offset: withdrawalOffset },
        },
      } = this.props;

      if (
        running
        || loading.some(
          url => url === `/loan?limit=${loanLimit}&offset=${loanOffset}`
        )
        || loading.some(
          url => url
            === `/repayment?limit=${repaymentLimit}&offset=${repaymentOffset}`
        )
        || loading.some(
          url => url
            === `/withdrawal?limit=${withdrawalLimit}&offset=${withdrawalOffset}`
        )
      ) {
        return;
      }
      this.setState({ running: true });
      if (activeTab === 0) {
        getLoans(
          loanLimit,
          Math.min(allLoans.length, loanOffset + loanLimit),
          application_id,
          () => this.setState({ running: false })
        );
      } else if (activeTab === 1) {
        getRepayments(
          repaymentLimit,
          Math.min(allRepayments.length, repaymentOffset + repaymentLimit),
          application_id,
          () => this.setState({ running: false })
        );
      } else if (activeTab === 2) {
        getWithdrawals(
          withdrawalLimit,
          Math.min(allWithdrawals.length, withdrawalOffset + withdrawalLimit),
          application_id,
          () => this.setState({ running: false })
        );
      }
    }
  };

  noop = () => null;

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  success = () => {
    // const {
    //   addCard,
    //   load,
    //   history: { push, location },
    // } = this.props;
    this.toggle();
    // addCard(data.reference, response => {
    //   Helpers.notification.success(response.message);
    //   load(() => {
    //     if (location.state && location.state.trigger === 'add-card') {
    //       push('/withdrawal');
    //     }
    //   });
    // });
  };

  close = () => {
    this.setState({ open: false });
  };

  clearAll = () => {
    const { clearLoans, clearRepayments, clearWithdrawals } = this.props;
    clearLoans();
    clearRepayments();
    clearWithdrawals();
  }

  refresh = (auto = false) => {
    const application_id = Helpers.token.get('user:application_id');
    const { user: { loading } } = this.props;
    if (loading.some(url => url === `/user?application_id=${application_id}` || url === `/portfolio?application_id=${application_id}`)) {
      return;
    }

    const {
      load, getLoans, getRepayments, getWithdrawals, getPortfolio,
      loan: { table: { limit: loanLimit, offset: loanOffset } },
      repayment: { table: { limit: repaymentLimit, offset: repaymentOffset } },
      withdrawal: { table: { limit: withdrawalLimit, offset: withdrawalOffset } },
      portfolio: { all: portfolioArray }, user: { user_id },
    } = this.props;
    if (!auto || (auto && user_id === 0)) {
      load(
        () => {},
        () => {},
        null,
        Number(application_id)
      );
    }
    if (!auto || (auto && portfolioArray.length <= 0)) {
      getPortfolio(0);
    }

    getLoans(loanLimit, loanOffset, application_id);
    getRepayments(repaymentLimit, repaymentOffset, application_id);
    getWithdrawals(withdrawalLimit, withdrawalOffset, application_id);
  }

  setMonths = () => {
    // calculate current and future month
    const today = new Date();
    const todayMonth = today.getMonth();
    this.setState({
      currentMonth:
        monthsOfYear[
          today.getDate() <= 3
            ? (((todayMonth - 1) % 12) + 12) % 12
            : todayMonth
        ],
      futureMonth:
        monthsOfYear[
          today.getDate() <= 3
            ? todayMonth
            : (((todayMonth + 1) % 12) + 12) % 12
        ],
    });
  };

  toggleMenu = () => {
    const { menu } = this.state;
    this.setState({ menu: !menu });
  };

  out = () => {
    const {
      logout, history, clearPortfolio, clearApplications,
    } = this.props;
    clearPortfolio();
    clearApplications();
    logout(history);
  };

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  switchPortfolio = application_id => {
    Helpers.token.set(application_id, 'user:application_id');
    const { application: { user: applications } } = this.props;
    const application = [allPortfolios, ...applications]?.filter(vendor => Number(vendor.application_id) === Number(application_id))[0];
    this.setState({
      app: {
        backgroundColor: application?.images.backgroundColor,
        banner: application?.images.banner,
        fontColor: (application?.images.backgroundColor).toLowerCase() === '#ffffff' ? 'black' : 'white',
        icon: application?.images.icon,
        name: application?.name,
      },
      application_id,
    },
    () => {
      this.clearAll();
      this.refresh();
      this.setMonths();
    });
  }

  // spreadArray = compactArr => {
  //   const spreadArr = [...compactArr];
  //   return spreadArr.map(obj => {
  //     const newObj = { ...obj };
  //     const keys = Object.keys(obj);
  //     keys.forEach(key => {
  //       if (typeof obj[key] === 'object' && obj[key] !== null) {
  //         const innerKeys = Object.keys(obj[key]);
  //         innerKeys.forEach(innerKey => {
  //           newObj[innerKey] = obj[key][innerKey];
  //         });
  //         delete newObj[key];
  //       }
  //     });
  //     return { ...newObj };
  //   });
  // }

  render() {
    const {
      open, menu, windowWidth, currentMonth, futureMonth, application_id, app, activeTab,
    } = this.state;
    const {
      user: userData,
      user: {
        email, user_id, info, loading, blacklist,
      },
      loan: { all: allLoans, total: loanTotal, table: { limit: loanLimit, offset: loanOffset } },
      repayment: { all: allRepayments, total: repaymentTotal, table: { limit: repaymentLimit, offset: repaymentOffset } },
      withdrawal: { all: allWithdrawals, total: withdrawalTotal, table: { limit: withdrawalLimit, offset: withdrawalOffset } },
      portfolio: { all: portfolioArray },
      logout,
      history,
      application: { user: applications },
      getNotifications,
      markNotificationsAsRead,
      markNotificationsAsDeleted,
    } = this.props;
    // console.log('applications: ', applications)

    let credit = 0;
    if (Number(application_id) === 0) {
      credit = portfolioArray.reduce((acc, item) => acc + item.amount, 0);
    } else {
      const currentPortfolio = portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0];
      credit = currentPortfolio?.amount || 0;
    }

    credit = credit < 0 ? 0 : credit;

    let day = '';
    let month = '';
    let year = '';
    if (info.repayment && info.repayment.minimum.date) {
      const date = new Date(info.repayment.minimum.date);
      day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      month = date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
      year = `${date.getFullYear()}`.substring(2);
    }

    return (
      <div id="dashboard-page">
        <div>
          <Modal
            dialogClassName="blacklist-modal"
            show={blacklist?.signin}
            onHide={() => this.handleModal(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="blacklist-modal-header" />
            <Modal.Body className="blacklist-modal-body">
              <p id="blackList-heading">Blocked Account</p>
              <p id="blackList-content">
                Your account has been blacklisted as a result of defaulting on
                your line of credit. Contact support for more information.
              </p>
              <a
                style={{ textDecoration: 'none' }}
                href="mailto:support@carrotcredit.com"
              >
                <Button size="small" background="light">
                  CONTACT SUPPORT
                </Button>
              </a>
              <button id="blacklist-modal-logOut" onClick={this.out}>
                Log Out
              </button>
            </Modal.Body>
          </Modal>
        </div>

        <Rates windowWidth={windowWidth} userData={userData} />

        <Hamburger
          menu={menu}
          toggleMenu={this.toggleMenu}
          logout={logout}
          history={history}
          firstname={
            userData
            && userData.firstname
            && Helpers.capitalizeFirstLetter3(userData.firstname)
          }
        />

        <Banner appSelect dropdown userData={userData} currentPage="dashboard" out={this.out} switchPortfolio={this.switchPortfolio} app={app} applications={[allPortfolios, ...applications]} getNotifications={getNotifications} markNotificationsAsRead={markNotificationsAsRead} markNotificationsAsDeleted={markNotificationsAsDeleted} />

        <div id="dashboard">
          <div id="dashboard-cards-left">
            <div id="dashboard-name-header" className="web-display-none mobile-display-block">
              <UserIcon userData={userData} toggleMenu={this.toggleMenu} windowWidth={windowWidth} />

              <div className="dashboard-refresh-button-small mobile-display-none web-display-block" onClick={() => this.refresh()}>
                <img
                  className={`icon zls${loading.some(url => url === `/user?application_id=${application_id}` || url === `/portfolio?application_id=${application_id}`)
                    ? ' loading' : ''}`}
                  src={refreshIcon}
                  alt="Refresh"
                  style={{
                    color: '#000000',
                  }}
                />
                Refresh
              </div>

              <div className="mobile-display-block web-display-none">
                <div className="notifications-icon-box">
                  <Notification
                    getNotifications={getNotifications}
                    markNotificationsAsRead={markNotificationsAsRead}
                    markNotificationsAsDeleted={markNotificationsAsDeleted}
                    size="small"
                    windowWidth={windowWidth}
                    userData={userData}
                  />
                </div>
              </div>

              <div className="mobile-display-block web-display-none">
                <MultiSelect key="dashboard-multiselect" switchPortfolio={this.switchPortfolio} app={app} applications={[allPortfolios, ...applications]} size="small" />
              </div>

              {/* <div id="dashboard-hamburger" className="web-display-none mobile-display-block">
                <img src={hamburger} alt="menu" onClick={this.toggleMenu} />
              </div> */}

              {/* <div id="dashboard-refresh-button" className="mobile-display-none">
                <Button size="small" background="green" onClick={this.refresh}>
                  <img
                    className={`icon zls${loading.some(url => url === '/user' || url === '/portfolio?application_id=1')
                      ? ' loading' : ''}`}
                    src={refresh}
                    alt="Refresh"
                  />
                  REFRESH
                </Button>
              </div> */}
            </div>
            <div id="dashboard-cards-upper">
              {Number(application_id) === 0 ? (
                <>
                  {loading.some(url => url === '/user' || url === `/user?application_id=${application_id}`)
                    ? (
                      <>
                        <Skeleton
                          count={1}
                          style={{
                            height: '107px',
                            width: '285.5px',
                          }}
                        />
                        <Skeleton
                          count={1}
                          style={{
                            height: '126px',
                            width: '285.5px',
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CurrentBalanceCard application_id={application_id} app={app} info={info} loading={loading} blacklist={blacklist} refresh={this.refresh} />
                        <DetailsCard info={info} allWithdrawals={allWithdrawals} blacklist={blacklist} credit={credit} day={day} month={month} year={year} currentMonth={currentMonth} futureMonth={futureMonth} />
                      </>
                    )}
                </>
              ) : (
                <>
                  {loading.some(
                    url => url === '/user'
                      || url === `/user?application_id=${application_id}`
                  ) ? (
                    <Skeleton
                      count={1}
                      style={{
                        height: '200px',
                        width: '285.5px',
                      }}
                    />
                    ) : (
                      <ApplicationCard
                        application_id={application_id}
                        app={app}
                        info={info}
                        loading={loading}
                        blacklist={blacklist}
                        refresh={this.refresh}
                      />
                    )}
                </>
              )}
            </div>

            <div id="dashboard-cards-lower">
              <ActionCardArray info={info} allWithdrawals={allWithdrawals} toggle={this.toggle} application_id={application_id} />
            </div>
          </div>

          <div>
            <div id="dashboard-recent-right">
              <div id="dashboard-recent-heading">
                Recent Activities
              </div>
              <Tabs
                current={num => this.setState({ activeTab: num })}
                styles={{ margin: '0 0 5px 10px', width: 'fit-content' }}
                tabs={[
                  { component: <Loans {...this.props} data={allLoans} key={0} loanLimit={loanLimit} loanOffset={loanOffset} application_id={application_id} />, title: 'Credit' },
                  {
                    component: <Repayments
                      {...this.props}
                      data={allRepayments}
                      key={1}
                      repaymentLimit={repaymentLimit}
                      repaymentOffset={repaymentOffset}
                      application_id={application_id}
                      loading={loading}
                    />,
                    title: 'Repayments',
                  },
                  {
                    component: <Withdrawals
                      {...this.props}
                      data={allWithdrawals}
                      key={2}
                      withdrawalLimit={withdrawalLimit}
                      withdrawalOffset={withdrawalOffset}
                      application_id={application_id}
                      loading={loading}
                    />,
                    title: 'Withdrawals',
                  },
                ]}
              />

              <div className="dashboard-pagination-container">
                {
                  (activeTab === 0 && Number(loanTotal) > 0) && (
                    <Pagination
                      total={Number(loanTotal)}
                      perPage={loanLimit}
                      handlePagination={this.handlePagination}
                      current={Helpers.math.divide(loanOffset, loanLimit)}
                    />
                  )
                }
                {
                  (activeTab === 1 && Number(repaymentTotal) > 0) && (
                    <Pagination
                      total={Number(repaymentTotal)}
                      perPage={repaymentLimit}
                      handlePagination={this.handlePagination}
                      current={Helpers.math.divide(repaymentOffset, repaymentLimit)}
                    />
                  )
                }
                {
                  (activeTab === 2 && Number(withdrawalTotal) > 0) && (
                    <Pagination
                      total={Number(withdrawalTotal)}
                      perPage={withdrawalLimit}
                      handlePagination={this.handlePagination}
                      current={Helpers.math.divide(withdrawalOffset, withdrawalLimit)}
                    />
                  )
                }
              </div>
            </div>

          </div>
        </div>

        {open && (
          <PaystackConsumer
            metadata={{ action: 'add-card', id: sha256(`${user_id}`), user_id }}
            publicKey={info.publicKey}
            amount={5000}
            email={
              process.env.REACT_APP_NODE_ENV === 'development'
                ? 'tech@carrotcredit.com'
                : email
            }
            channels={['card']}
            text="Carrot-Paystack"
            onSuccess={this.success}
            onClose={this.close}
          >
            {({ initializePayment }) => <>{initializePayment()}</>}
          </PaystackConsumer>
        )}
      </div>
    );
  }
}

export default Dashboard;
