import React from 'react';

const RevenueCard = ({
  image, Icon1, Heading, dec,
}) => (
  <div>
    <div className="Square ">
      <div className="MainImage">
        <div className="battery">
          <img src={image} alt="sd" className="img-fluid" />
        </div>
        <div className="icon1 ">
          <img src={Icon1} alt="sd" />
        </div>
      </div>
      <p className="heading-text">{Heading}</p>
      <p className="content-text">{dec}</p>
    </div>
  </div>
);

export default RevenueCard;
