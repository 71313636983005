/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Footer } from '../../components';

function PrivacyPolicy() {
  return (
    <div className="agreementDiv">
      <div className="navbar-wrap">
        <Navbar />
      </div>
      <div>
        <h1>Privacy Policy</h1>
        <div className="text">

          <ul>
            <li>
              Download
              {' '}
              <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/Carrot Credit - Privacy Policy.pdf" target="_blank" download>Privacy Policy</Link>
            </li>
            <li>
              View
              {' '}
              <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/user-agreement">User Agreement</Link>
            </li>
          </ul>

          <br />
          <h6 style={{ fontWeight: 'bold' }}>PRIVACY NOTICE</h6>
          <p>
            Carrot Digital Solution Limited ("Carrot", "us". "we") is a limited liability company
            registered in Nigeria with its registered address at 11 Durban Street, Abuja, FCT, Nigeria.
            We Provide a platform (the "Platform") for lending and borrowing transactions conducted
            between our customers. The Platform is owned and operated by Carrot.

            <br />
            Carrot is committed to protecting and respecting your privacy. Carrot takes all necessary
            steps to ensure that your personal data and to prevent the misuse of your personal data.
            Detailed information on how we do it, as well as why and how we collect, store and use
            your personal, could be found below.

            <br />

            The privacy notice does not apply to any products, services, websites, or content that are
            offered by third parties or have their own privacy notice.
          </p>

          <p>
            <b> 1. General </b>

            <br />

            <b> 1.1 </b>
            {' '}
            Please read this privacy notice carefully as it is sets out the basis
            on which we collect any personal information from you, or that you provide to us and
            how we use it in the operation of the Platform.

            <br />

            <b> 1.2 </b>
            {' '}
            This privacy notice covers:

            <br />

            (a) registered users of the Platform; and

            <br />

            (b) guest on the Platform.

            <br />

            <b> 1.3 </b>
            {' '}
            Any changes we may make to this privacy notice in the future will be posted
            on this page and, where appropriate, notified to you by email. Please check back frequently
            to see any updates or changes to our privacy notice.

            <br />
            <br />

            <b> 2. Data that we collect from you </b>

            <br />

            (a) Information and content you provide

            <br />

            (b) Platform credentials;

            <br />

            (c) Business information.

            <br />

            <b> 2.1 </b>
            {' '}
            We also collect and store data that you submit to us via email and through
            our contact form.

            <br />

            <b> 2.2 </b>
            {' '}
            We collect the information above by using cookies. Kindly visit our webiste
            <a
              rel="noopener noreferrer"
              href="https://www.carrotcredit.com/"
              target="_blank"
            >
              www.carrotcredit.com
            </a>
            to download. It explain what cookies we use on our Platform, why we use them, and how you can
            control them.

            <br />
            <br />

            <b> 3.  The type of data we may request from you are: </b>

            <br />

            (a) contact information - including your name, date of birth, address, postcode, and other
            contact information such as email address and telephone/mobile number and (where applicable)
            the contact details of your first of kin;

            <br />

            (b) the bank account details to make or recieve payments on the Platform;

            <br />

            (c) your passwords and security question answers;

            <br />

            (d) your financial interests, financial position, or loan repayment performance;

            <br />

            (e) answers to questions required by third party credit reference agencies for
            identification purposes;

            <br />

            (f) information about your activity on the Platform; and

            <br />

            (g) information from directors/individuals associated with you/or your business.

            <br />

            <b> 3.1 </b>
            {' '}
            We may retain copies of the following information about you:

            <br />

            (a) any correspondence you send us, details of your registration history, and any
            materials you post on the Platform;

            <br />

            (b) passports or other identification evidence that you provide for anti-money
            laundering and anti-fraud purposes;

            <br />

            (c) record of any survey that you may be asked to complete for research purposes,
            although you do not have to respond to them;

            <br />

            (d) details of transactions you carry out through the Platform and the receipt and
            disbursement of repayments;

            <br />

            (e) details of your visit to the Platform and the resources that you access;

            <br />

            (f) where explicit consent has been provided, information about your physical or mental
            health or conditions (where neccessary and appropriate); and

            <br />

            (g) where explicit consent has been provided, information relating any criminal proceedings
            in which you have been involved.

            <br />

            <b> 3.2 </b>
            {' '}
            If you give us false or inaccurate information, either directly or through an
            agent, and we suspect or identify fraud, we will record this.

            <br />

            <b> 3.3. </b>
            {' '}
            We may also be provided with information about you from the third-party
            brokers, introducers, or other referrers, who obtained that information from you directly.

            <br />
            <br />

            <b> 4.  Information from other sources </b>

            <br />

            In relation to borrowers only, we may also check information about you held on our own
            records and also obtain information from credit reference agencies about you and your
            financial associates' personal credit behavior and personal credit accounts. We may also
            search fraud prevention agencies for information on you and your financial associates and
            business partners.

            <br />
            <br />

            <b> 5.  What do we do with information we collect from users of the Platform? </b>

            <br />

            The main reason we use this information to provide you with details about our products and
            services. Where it is in our legitimate interest to do so, we (or third party data processors,
            agents, and sub-contractors acting on our behalf) may also use the information:

            <br />

            (a) to enrich your experience and interaction with the Website by allowing you to store your
            details so that your preferences are retained when revisit the Platform;

            <br />

            (b) to analyze site usage and improve our services;

            <br />

            (c) to deliver administrative notices, alerts, and communications relevant to your use of the
            services;

            <br />

            (d) to contact you from time to time to inform you about new features, how to troubleshoot
            problems, and to protect you against fraud or other criminal activity;

            <br />

            (e) To verify your identity and assist you, in case you lose or forget your login/password
            details;

            <br />

            (f) For complaince with laws which impose upon Carrot the collection and/or further processing
            of certain kinds of Personal Data;

            <br />

            (g) To prevent and detect any misuse of the Website, or any fraudulent activities carried out
            through the Website ("Misuse/Fraud")

            <br />
            <br />

            <b> 6.  Our Principle of Data Processing </b>

            <ul>
              <li>
                Personal data will be processed fairly, lawfully, and in a transparent manner;
              </li>
              <li>
                Personal data will be processed for a specific purpose and not in a way that is
                compatible with the purpose which Carrot has collected it;
              </li>
              <li>
                Your personal data is adequate, relevant, and limited to what is necessary for the
                purposes for which it is processed;
              </li>
              <li>
                Your personal data will be kept accurate and, where necessary kept up to date;
              </li>
              <li>
                Your personal data will be kept for no longer than is necessary for the purposes
                for which it is processed;
              </li>
              <li>
                We will take appropriate steps to keep your personal details secure.
              </li>
            </ul>

            <b> 7.  Lawful Basis for Processing Your Data </b>

            <br />

            <b> Consent </b>

            <br />

            When you give clear consent for us to process your personal data for a specific purpose or
            according to Section 8 of this notice.

            <br />

            <b> Contract </b>

            <br />

            If the processing of your data is necessary for a contract you have with us, or because we
            have asked you to take specific steps before entering into that contract.

            <br />

            <b> Legal Obligation </b>

            <br />

            If the processing of your personal data is necessary where there is a statutory obligation
            upon us.

            <br />

            <b> Legitimate interests </b>

            <br />

            Processing your data is necessary for our legitimate interests of a third party, provided
            those interests are not outweighed by your rights and interests. These legitimate interests
            are:

            <ul>
              <li>
                gaining insights from your behavior on our website or in our app
              </li>
              <li>
                delivering, developing, and improving Carrot service
              </li>
              <li>
                enabling us to enhance, customize or modify our services and communications
              </li>
              <li>
                determining whether marketing campaigns are effective
              </li>
              <li>
                enhancing data security
              </li>
            </ul>

            In each case, these legitimate interests are only valid if they are not outweighed by your
            rights and interests

            <br />
            <br />

            <b> 8.  What constitutes consent? </b>

            <br />

            We will not ask for your personal data unless we need it to provide services to you. At any
            point where consent is the appropriate lawful basis for processing your personal data, we
            will provide you the option to either accept or not. In addition, whenever we introduce new
            services and technologies, we will ensure you understand and agree to any new ways in which
            your information will be processed.

            <br />

            You will be considered to have given to given your consent to Carrot for the processing of
            your your personal data when;

            <ol type="I">
              <li>
                You complete any form issued by Carrot at any of our service points (mobile, online,
                in-branch, etc.) requesting such personal information
              </li>
              <li>
                You register, check, or tick the acceptance box on any of our electronic platforms
                (Online or Mobile) relating to terms and conditions of any service or product offered;
              </li>
              <li>
                You use any service or product offered by Carrot.
              </li>
            </ol>

            If we ask for your personal information for a secondary reason, like marketing, we will
            either ask you directly for your expressed consent or provide you with an opportunity to
            say no.

            <br />

            <b> How do I draw my consent? </b>

            <br />

            If after you opt-in, you change your mind, you may withdraw your consent to the continued
            processing of your personal data, at any time, by contacting us at
            {' '}
            <a
              href="mailto:support@carrotcredit.com"
            >
              support@carrotcredit.com
            </a>

            <br />
            <br />

            <b> 9.  Who do we share your information with? </b>

            <br />

            <b> 9.1 </b>
            {' '}
            We may disclose your personal information to third parties where it is in
            our legitimate interest to do so including for the following reasons:

            <br />

            (a) We may share your information with analytics and search engine providers that assist us
            in the improvement and optimization of our sites;

            <br />

            (b) Entities engaged in order to provide the Services (e.g., hosting providers or e-mail
            platform providers);

            <br />

            (c) Persons authorized to perform technical maintenance (including maintenance of network
            equipment and electronic communications networks);

            <br />

            (d) Public entities, bodies or authorities to whom your Personal Data may be disclosed, in
            accordance with the applicable law or binding orders of those entities, bodies or
            authorities;

            <br />

            (e) Persons authoritized by Carrot to process Personal Data needed to carry out activities
            strictly related to the provision of the Services, who have undertaken an obligation of
            confidentiality or are subject to an appropriate legal obligation of confidentiality (e.g.,
            employees of Carrot);

            <br />

            (f) We may share your personal information with companies and other third parties performing
            services on our behalf (for example KYC service provider, credit reference agencies,
            customer relationship management providers, or other service providers) who will only use
            the information to provide that service. We may also share your personal information with
            other members of our corporate group, or a purchaser or potential purchaser of our
            business; and

            <br />

            (g) We may share alerts and information derived from identity verification checks with third
            parties for the purpose of anti-money laundering and fraud prevention.

            <br />

            <b> 9.2 </b>
            {' '}
            Save as set out in this privacy notice, we will not sell or disclose your
            data to any third party.

            <br />
            <br />

            <b> 10. Where we store your personal data </b>

            <br />

            <b> 10.1 </b>
            {' '}
            We are committed to ensuring that your information is safe and take all steps
            reasonably necessaryto ensure that your data is treated securely and in accordance with
            this privacy notice. In order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to safeguard and secure the
            information we collect online.

            <br />
            <br />

            <b> 11. How long we keep your information </b>

            <br />

            <b> 11.1 </b>
            {' '}
            We will keep your data for as long as necessary to fulfill the purposes
            described in this privacy notice. However, we will also retain data subject to relevant
            provisions of applicable laws.

            <br />
            <br />

            <b> 12. Marketing and Communications </b>

            <br />

            <b> 12.1 </b>
            {' '}
            We strive to provide you with choices regarding certain personal data uses,
            particularly around marketing and advertising.

            <br />

            <b> 12.2 </b>
            {' '}
            If you have registered with us or have previously asked us for information
            on our products or services and you have not opted out of receiving that marketing
            information, we may send you information on our range of products, services, promotions,
            special offers, and other information which we think you may find interesting by phone,
            email and/or SMS.

            <br />

            <b> 12.3 </b>
            {' '}
            We will get your express opt-in consent before we share your personal data
            with any third party marketing purposes.

            <br />

            <b> 12.4 </b>
            {' '}
            You can ask us or third parties to stop sending you marketing messages at any
            time by contacting us by email at
            {' '}
            <a href="mailto:support@carrotcredit.com">
              support@carrotcredit.com
            </a>

            <br />
            <br />

            <b> 13. Your Rights </b>

            <br />

            You have the following rights:

            <br />

            (a) the right to request access to your personal data;

            <br />

            (b) the right to the erasure of your personal data ("right to be forgotten");

            <br />

            (c) the right to rectify or amend inaccurate or incomplete personal data;

            <br />

            (d) the right to object to the processing of your personal data;

            <br />

            (e) the right to portability of data;

            <br />

            (f) the right to lodge a complaint with the Court, National Information Technology
            Development Agency (NITDA), or any other relevant supervisory authority.

            <br />
            <br />

            <b> 14. Security of Data </b>

            <br />

            We are very peculiar about preserving your privacy and protecting your data. Therefore, to
            avoid the loss, theft, misuse, and unauthorized access, disclosure, alteration, and
            destruction of your information, we have put in place a range of administrative, technical,
            organizational, and physical safeguards. Regular third-party audits are conducted at Carrot
            to ensure we use the latest technologies and best practice to protect data. Our employees
            are trained in data security and are bound by strict confidentiality provisions. Despite
            this, we cannot completely guarantee the security of any information you transmit via our
            online channels, as the internet is not an entirely secure place.

            <br />
            <br />

            <b> 15. International Transfer of Data </b>

            <br />

            Your Personal Data may be transferred to Recipients located in several different countries. To
            achieve the purposes described in this Privacy Notice, we transfer your Personal Data to
            countries that may not offer an adequate level of protection or not considered to have
            adequate law by the National Information Technology Development Agency. Where Personal Data
            is to be transferred to a country outside Nigeria, Carrot shall put adequate measures
            in place to ensure the security of such Personal Data. In particular, Carrot shall, among
            other things, conduct a detailed transfer impact assessment of whether the said country is on
            NITDA'Whilelist of Country with adequate data protection laws.

            <br />

            Our data transfers to the countries that do not offer an adequate level of protection are
            subject either of the conditions in accordance with the Nigeria Data Protection Regulation.
            Carrot will therefore only transfer Personal Data out of Nigeria on one of the following
            conditions:

            <ol type="a">
              <li>
                The consent of the Data Subject has been obtained;
              </li>
              <li>
                The transfer is necessary for the performance of a contract between Carrot and the Data
                Subject or implementation of pre-contractual measures taken at the Data Subject's
                request;
              </li>
              <li>
                The transfer is necessary to conclude a contract between Carrot and a third party in the
                interest of the Data Subject;
              </li>
              <li>
                The transfer is necessary for reason of public interest;
              </li>
              <li>
                The transfer is for the establishment, exercise, or defense of legal claims;
              </li>
              <li>
                The transfer is necessary in order to protect the vital interests of the Data Subjects
                or other persons, where the Data Subject is physically or legally incapable of giving
                consent.
              </li>
            </ol>

            To obtain any relevant  information regarding any transfers of your Personal Data to
            third-party countries (including the relevant transfer mechanisms), please contact us at
            <a href="mailto:support@carrotcredit.com">support@carrotcredit.com</a>

            <br />
            <br />

            <b> 16. Remedy in the event of a violation of privacy notice</b>

            <br />

            Where there is any perceived violation of your rights, we shall take appropriate steps to
            remedy such violations, once confirmed.You shall be appropriately informed of the remedies
            employed. In the event of a data breach, we shall within 72 (seventy-two) hours of having
            knowledge of such breach report the details of the breach to NITDA. Furthermore, we will
            notify you immediately via email if the breach will result in risk and danger to your rights
            and freedoms.

            <br />

            If you have any complaint regarding our Compliance with this Privacy Notice, please email us
            at
            {' '}
            <a href="mailto:support@carrotcredit.com">support@carrotcredit.com.</a>
            {' '}
            We will investigate
            and attempt to resolve complaints and disputes regarding the use and disclosure of personal
            information within thirty (30) days in accordance with this Privacy Policy and in accordance with
            applicable law and regulation.

            <br />

            If you feel that your Personal Data has not been handled correctly or you are unhappy with our
            response to any requests you have made to us regarding the use of your Personal Data, you have a
            right to lodge complaint with the NITDA. The contact details are:

            <br />

            National Information Technology Development Agency

            <br />

            Tel: +234929220263, +2348168401851, +2347052420189

            <br />

            Email:
            {' '}
            <a href="mailto:info@nitda.gov.ng">info@nitda.gov.ng</a>

            <br />

            Website:
            {' '}
            <a
              rel="noopener noreferrer"
              href="https://www.nitda.gov.ng/"
              target="_blank"
            >
              www.nitda.gov.ng
            </a>

            <br />
            <br />

            <b> 17. Contacting us</b>

            <br />

            If you have any complaints or any questions about any aspects of this privacy notice or
            your information, or to exercise any of your rights as described in this privacy notice
            or under data protection laws, you can contact us at
            {' '}
            <a
              href="mailto:support@carrotcredit.com"
            >
              support@carrotcredit.com.
            </a>

          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
