import React, { Component } from 'react';

import emptyStateScreen from '../assets/images/empty-state-screen.png';

class Empty extends Component {
  render() {
    return (
      <div id="empty">
        <img src={emptyStateScreen} alt="Empty" />
        <label htmlFor="empty">No transactions yet</label>
      </div>
    );
  }
}

export default Empty;
