import React from 'react';
import * as Helpers from '../helpers';
import Button from './Button';

import getStartedImage from '../assets/images/get-started.png';

const refs = [];
class GetStarted extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    errorHeading: '',
    errorText: '',
    getStartedData: {
      email: '',
      emailPermit: false,
      fullname: '',
      message: '',
      userType: 'Individual',
    },
    showError: false,
    showSuccess: false,
  };

  componentDidMount() {
    refs.push(React.createRef());
    refs.push(React.createRef());
  }

  componentDidUpdate(prevprops) {
    const { businessEmail } = this.props;
    if (businessEmail !== prevprops.businessEmail) {
      this.handleFormInput('email', businessEmail);
    }
  }

  handleFormInput = (dataField, newData) => {
    const { getStartedData } = this.state;
    this.setState({
      getStartedData: {
        ...getStartedData,
        [dataField]: newData,
      },
    });
  };

  handleInputChange = e => {
    const dataField = e.target.name;
    const newData = e.target.value;
    this.handleFormInput(dataField, newData);
  };

  handleCheck = field => e => {
    const { getStartedData } = this.state;
    this.setState({
      getStartedData: {
        ...getStartedData,
        [field]: e.target.checked,
      },
    });
  };

  handleFormSubmit = () => {
    const { contact } = this.props;
    const { getStartedData } = this.state;
    if (!(/^[^@]+@[^@]+\.[^@]+$/.test(getStartedData.email.trim()))) {
      this.setState({ errorHeading: 'Unable to Submit', errorText: 'Please enter a valid email address', showError: true });
      this.scrollToElement(1);
      setTimeout(() => {
        this.setState({ showError: false });
      }, 5000);
    } else {
      contact(
        getStartedData.email,
        getStartedData.emailPermit,
        getStartedData.fullname ? Helpers.capitalizeFirstLetter3(getStartedData.fullname) : '-',
        getStartedData.message ? getStartedData.message : '-',
        getStartedData.userType,
        () => {
          this.setState({ showSuccess: true });
          this.scrollToElement(0);
          setTimeout(() => {
            this.setState({ showSuccess: false });
          }, 5000);
          setTimeout(() => {
            this.setState(
              {
                getStartedData: {
                  email: '',
                  emailPermit: false,
                  fullname: '',
                  message: '',
                  userType: getStartedData.userType,
                },
              }
            );
          }, 2000);
        },
        () => {
          this.setState({ showError: true });
          this.scrollToElement(1);
          setTimeout(() => {
            this.setState({ showError: false });
          }, 5000);
        }
      );
    }
  };

  scrollToElement = index => {
    setTimeout(() => {
      refs[index].current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }, 500);
  }

  render() {
    const {
      getStartedData, showSuccess, showError, errorHeading, errorText,
    } = this.state;
    const { loading } = this.props;
    return (
      <section id="get-started-section">
        <div className="heading-text">
          Talk to us!
        </div>

        <div className="get-started-content">
          <div className="get-started-image">
            <img src={getStartedImage} alt="get started" />
          </div>

          <div id="getStartedForm" className="get-started-input">
            <div id="dropDownDiv" className="dropdown marginTopNBottom">
              <button className="btn btn-outline formHeight formWidth formBorderRadius paddingTopNBottom noBorderOutline whiteBackground" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {getStartedData.userType}
                <span><i className="bi bi-chevron-down" /></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><div id="individual" className="dropdown-item" href="" onClick={() => this.handleFormInput('userType', 'Individual')}>Individual</div></li>
                <li><div id="business" className="dropdown-item" href="" onClick={() => this.handleFormInput('userType', 'Business')}>Business</div></li>
              </ul>
            </div>

            <div className="marginTopNBottom">
              <input type="text" className="form-control formBorderRadius formHeight formWidth noBorderOutline paddingTopNBottom" id="formGroupExampleInput" placeholder="Name" name="fullname" onChange={this.handleInputChange} value={getStartedData.fullname} />
            </div>

            <div className="marginTopNBottom">
              <input type="email" className="form-control formBorderRadius formWidth formHeight noBorderOutline paddingTopNBottom" id="exampleFormControlInput1" placeholder="Email" name="email" onChange={this.handleInputChange} value={getStartedData.email} />
            </div>

            <div className="marginTopNBottom extraBottomMargin">
              <textarea className="form-control textAreaBorderRadius formWidth textAreaHeight noBorderOutline paddingTopNBottom" id="exampleFormControlTextarea1" rows="3" placeholder="Message" name="message" onChange={this.handleInputChange} maxLength="250" value={getStartedData.message} />
            </div>

            <Button onClick={this.handleFormSubmit} loading={loading.some(url => url === '/admin/contact')} cta disabled={getStartedData.email === ''}>Submit</Button>

            <div className="form-check checkForm formWidth">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={this.handleCheck('emailPermit')} checked={getStartedData.emailPermit} />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Send me helpful info
              </label>
            </div>
            <div className="terms formWidth">
              By clicking “Submit”, you agree to Carrot&apos;s
              <br />
              <a href="/user-agreement">User Agreement</a>
              {' '}
              and
              {' '}
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        </div>

        { showSuccess && (
          <div ref={refs[0]} id="successPopup" className="get-started-success">
            { getStartedData.userType !== 'Business' && (
              <>
                <div className="heading-text">
                  We have received your email
                </div>
                <div className="content-text">
                  Thank you for reaching out to us!
                </div>
              </>
            )}

            { getStartedData.userType === 'Business' && (
              <>
                <div className="heading-text">
                  Thank you for reaching out to us!
                </div>
                <div className="content-text">
                  Check your email for the next steps
                </div>
              </>
            )}
          </div>
        )}

        { showError && (
          <div ref={refs[1]} id="errorPopup" className="get-started-success">
            <div className="heading-text">
              {errorHeading || 'Unable to submit Form'}
            </div>
            <div className="content-text">
              {errorText || 'You did not fill the form correctly'}
            </div>
          </div>
        )}
      </section>
    );
  }
}
export default GetStarted;
