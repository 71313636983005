import styled from 'styled-components';
import { deviceQuery } from './themes';

export const FaqsContainer = styled.div`
  font-family: 'Gilroy-Regular';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.02em;
`;

export const Heading = styled.h1`
  font-family: 'Gilroy-ExtraBold';
  color: #121e0e;
  font-size: 68px;
  line-height: 73px;
  margin: 0;
  max-width: 600px;
  margin: auto;
  text-align: center;
  @media ${deviceQuery.tablet} {
    font-size: 42px;
    line-height: 58px;
    margin: 0;
    margin-bottom: 8px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    margin: 10px 10px 0 20px;
  }
  img.hand {
    width: 45%;
    max-width: 500px;
    min-width: 435px;
    margin: 0 0 -200px -70px;
  }
  img.scroll {
    margin: 29px 0 0 0;
    cursor: pointer;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
  }
  .bounce-6 {
    animation-name: bounce-6;
    animation-timing-function: ease;
  }
  @keyframes bounce-6 {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-10px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-2px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
  @media ${deviceQuery.tablet} {
    margin: 0 27px 27px 27px;
    display: block;
    div {
      width: 100%;
      margin: 0;
    }
    img.hand {
      display: none;
    }
    img.scroll {
      margin: 14px 0 20px 0;
    }
    text-align: center;
    padding: 0;
  }
`;

export const Caption = styled.p`
  font-family: 'Gilroy-Regular';
  font-size: 18.6px;
  line-height: 34px;
  color: #121e0e;
  max-width: 450px;
  margin: auto;
  text-align: center;
  margin-top: 25px;
  @media ${deviceQuery.tablet} {
    font-size: 16px;
    line-height: 28.7px;
    margin: 0;
    margin: auto;
  }
`;
export const AboutSection = styled.div`
  display: flex;
  width: 80%;
  margin: 68px auto 60px 10%;
  justify-content: space-between;
  @media ${deviceQuery.tablet} {
    display: block;
  }
`;
export const ListHeading = styled.p`
  font-family: 'Gilroy-SemiBold';
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  cursor: pointer;
  width: 80%;
  margin: 27px 0;
  @media ${deviceQuery.tablet} {
    font-size: 16px !important;
  }
`;
export const ListIcon = styled.span`
  margin-right: 38px;
  color: #fe5000;
  font-size: 18px;
  cursor: pointer;
`;
export const ListCaption = styled.p`
  font-size: 16px;
  margin-left: 48px;
  width: 92%;
  overflow: hidden;
  @media ${deviceQuery.tablet} {
    font-size: 14px;
    width: auto;
    margin-top: -5px;
  }
`;
export const ListContent = styled.div`
  display: flex;
  align-items: center;
`;
export const Info = styled.div`
  border-bottom: 0.7px solid #353535;
  width: 80%;
  @media ${deviceQuery.tablet} {
    width: 85%;
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: -24px;
  }
`;
export const AboutHeader = styled.p`
  font-size: 18px;
  font-weight: 600;
  @media ${deviceQuery.tablet} {
    display: none;
  }
`;
export const MobileAboutHeader = styled.p`
  margin: 0 0 10px 0;
  width: 240px;
`;

export const AboutList = styled.div`
  & p {
    cursor: pointer;
    white-space: pre;
    margin-right: 50px;
  }
  @media ${deviceQuery.tablet} {
    display: none;
  }
`;

export const AboutDropDown = styled.div`
  display: block;
  @media ${deviceQuery.tablet} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    p {
      width: auto;
      margin: 0;
      border-bottom: 1.5px solid #353535;
      font-family: 'Gilroy-ExtraBold';
      font-weight: 900;
    }
  }
`;
export const Drop = styled.div`
  display: none;
  @media ${deviceQuery.tablet} {
    display: block;
    margin: auto;
    margin-top: -41px;
    p {
      cursor: pointer;
    }
  }
`;
export const ToggleIcon = styled.div`
  cursor: pointer;
  color: #00ab84;
  margin-left: 12px;
`;

export const Horizontal = styled.div`
  height: 13px;
  background-color: #fe5000;
  margin-top: -37px;
  margin-bottom: 37px;
  margin-right: 50px;

  @media ${deviceQuery.tablet} {
    display: none;
  }
`;

export const HorizontalMobile = styled.div`
  display: none;
  @media ${deviceQuery.tablet} {
    display: block;
    height: 13px;
    background-color: #fe5000;
    width: 80%;
    margin: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 79px 0 79px;
  img {
    cursor: pointer;
  }
  img.hamburger-icon {
    display: none;
  }
  div.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Gilroy-SemiBold';
    font-size: 16px;
    line-height: 25.2px;
    column-gap: 45px;
  }
  div.links div:hover {
    color: #fe5000;
  }
  @media ${deviceQuery.tablet} {
    margin: 41px 27px 33px 27px;
    img.hamburger-icon {
      display: block;
    }
    div.links {
      display: none;
    }
  }
`;

export const Button = styled.button`
  height: 46px;
  background-color: #000000;
  width: 154px;
  border: none;
  border-radius: 50px;
  color: #ffffff;
`;
