import React, { Component } from 'react';
import PropTypes from 'prop-types';

import done from '../assets/images/done.svg';
import Button from './Button';
import Page from './Page';

class Successful extends Component {
  back = () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger: 'refresh',
    });
  }

  render() {
    const {
      title, subtitle, background, buttonText,
    } = this.props;
    return (
      <Page
        title="Success"
        alignContentMobile="align-center"
        background={background}
        footer={<></>}
      >
        <div id="application-successful">
          <div id="successful-logo-container">
            <img src={done} id="successful-logo" alt={`${title}\n${title}`} />
          </div>

          <div className="heading successful">
            {title}
          </div>

          <div className="subheading successful">
            {subtitle}
          </div>

          <div id="successful-button">
            <Button onClick={this.back} size="large" background="">{buttonText || 'GO TO DASHBOARD'}</Button>
          </div>
        </div>
      </Page>
    );
  }
}

Successful.propTypes = {
  background: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Successful;
