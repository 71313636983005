import React, { Component } from 'react';

import Info from './Info';

import { Keypad, Page, Button } from '../../components';
import * as Helpers from '../../helpers';

import tickCircle from '../../assets/images/tick-circle-orange.svg';

class Request extends Component {
  state = {
    appId: 0,
    firstLoad: true,
    show: 'selectVendor',
  };

  componentDidMount = () => {
    const { getUserApplications, user: { user_id }, application: { user: applications } } = this.props;
    if (applications.length === 0) {
      getUserApplications(user_id);
    }
    if (Number(Helpers.token.get('user:application_id')) > 0) {
      this.setState({ show: 'offer' });
    }
  }

  componentDidUpdate = prevProps => {
    const { history, user: { blacklist } } = this.props;
    if (blacklist?.signin || blacklist?.loan) {
      Helpers.notification.error('You are not allowed to use this service.');
      history.push('/dashboard');
      return;
    }

    const { firstLoad } = this.state;
    const { setAmount, amount, thirdParty } = this.props;
    if (amount !== prevProps.amount) {
      setAmount(amount);
    }

    if (thirdParty && firstLoad) {
      this.setState({ firstLoad: false, show: 'offer' });
    }
  }

  changeScreen = show => () => {
    this.setState({ show });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/dashboard', {
      trigger,
    });
  }

  setAppId = id => {
    this.setState({ appId: id });
    Helpers.token.set(id, 'user:application_id');

    const { history, portfolio: { all: portfolioArray } } = this.props;
    const application_id = id;
    const waitlist = Number(application_id) === 0
      ? '0'
      : portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0]?.waitlist;
    if (waitlist === '1') {
      history.push('/waitlist');
    }
  }

  render() {
    const { show, appId } = this.state;
    const {
      next, setAmount, setTenure, tenure, history,
      user: { loading, user_id, info }, application: { user: applications },
      portfolio: { all: portfolioArray },
    } = this.props;

    const application_id = Helpers.token.get('user:application_id');
    const currentPortfolio = portfolioArray.filter(portfolio => Number(portfolio.application.application_id) === Number(application_id))[0];
    let amount = currentPortfolio?.amount || 0;
    amount = amount < 0 ? 0 : amount;
    // eslint-disable-next-line no-console
    console.log(`Portfolio: (Total: ${currentPortfolio?.amount || 0}, Allowed: ${amount}`, ', Debt:', info?.debt?.current, ', Credit:', amount);
    amount = Helpers.math.divide(amount, 100); // important

    switch (show) {
    case 'offer':
      return (
        <Info
          {...this.props}
          amount={amount}
          setAmount={setAmount}
          changeScreen={this.changeScreen('keypad')}
          next={next}
          back={appId === 0 ? () => history.push('/dashboard') : () => this.setState({ show: 'selectVendor' })}
          applications={applications}
        />
      );
    case 'keypad':
      return (
        <Keypad
          back={process.env.REACT_APP_NODE_ENV === 'development' ? this.changeScreen('offer') : () => history.push('/dashboard')}
          background="illustration-2"
          minimum={Number(process.env.REACT_APP_MINIMUM_AMOUNT) / 100}
          heading="How much do you want to request?"
          next={next}
          amount={amount}
          setAmount={setAmount}
          tenure={tenure}
          setTenure={setTenure}
          buttonText="APPLY"
          buttonDisabled={!amount > 0}
        />
      );
    case 'selectVendor':
      return (
        <Page
          title="Offer"
          alignContentMobile="align-left"
          back={this.back()}
          background="illustration-2"
          hasHeader
          hasLogo
          hm="100px 0 0 0"
          lm="53px 0 0 0"
          footer={<></>}
        >
          <div className="heading vendor">
            Select Vendor
          </div>

          <div className="subheading vendor">
            We are consistently working on adding more fintech firms to this list
          </div>

          <div className="vendor-cards">
            {applications.map((vendor, i) => (
              <button key={i} className="item highlight-light" onClick={() => this.setAppId(vendor.application_id)}>
                <img src={/^http/.test(vendor.images.logo) ? vendor.images.logo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.logo)}`} alt="" className="vendor-logo" />
                {appId === vendor.application_id && <img className="tick" src={tickCircle} alt="" />}
              </button>
            ))}
          </div>

          <div className="vendor-button">
            <Button
              disabled={appId === 0}
              loading={loading.some(url => url === `/application${user_id ? `?user_id=${user_id}` : ''}`)}
              onClick={() => this.setState({ show: 'offer' })}
              size="large"
              background="light"
            >
              APPLY NOW
            </Button>
          </div>
        </Page>
      );
    default:
      return null;
    }
  }
}

export default Request;
