import React from 'react';
import { Link } from 'react-router-dom';
import { countries } from 'countries-list';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import libphonenumber from 'google-libphonenumber';

import {
  Button, Page, Input, Tabs, DoubleInput, Loading
} from '../../components';

import SigninOptions from './SigninOptions';

import * as Helpers from '../../helpers';

const countriesKeys = Object.keys(countries);
class Signin extends React.Component {
  state = {
    activeTab: 0,
    applicationId: 0,
    callingCode: '234',
    countryCode: 'NG',
    dataError: false,
    dataExtracted: false,
    email: '',
    invalidMessage: {
      email: '',
      mobile: '',
    },
    mobile: '',
    paramCheck: true,
    password: '',
    persistLogin: true,
    prevShow: '',
    show: 'password',
    showPassword: false,
  };

  componentDidMount = () => {
    const user = Helpers.token.get('user');
    const userEmail = user && user.includes('@') && user.includes('.') ? user : '';
    const userMobile = user && !user.includes('@') && !user.includes('.') ? user.slice(4) : '';
    setTimeout(() => {
      this.setState({ email: userEmail, mobile: userMobile });
    }, 1000);
  }

  handleParamCheck = () => {
    this.setState({ paramCheck: false });
    const { location, history, match: { params: { code: pCode } } } = this.props;
    const code = pCode;
    if (code) {
      Helpers.token.set(code, 'signup:code');
      const query = location.search;
      if (query) {
        Helpers.token.set(query, 'signup:query');
      }
      history.push('/signin');
    }

    const storedCode = Helpers.token.get('signup:code');
    const storedQuery = Helpers.token.get('signup:query');
    if (storedCode) {
      const { register } = this.props;
      register(
        storedCode,
        storedQuery || '',
        response => {
          if (response && response.data && response.data.redirect) {
            Helpers.notification.success(response.message);
            setTimeout(() => {
              window.location = response.data.redirect;
            }, 1000);
          } else {
            this.setState({ dataExtracted: true });
            Helpers.notification.success(response.message);
          }
        },
        () => {
          this.setState({ dataExtracted: true });
        }
      );
      Helpers.token.remove('signup:code');
      Helpers.token.remove('signup:query');
    } else {
      this.setState({ dataExtracted: true });
    }

    const getParams = new URLSearchParams(location.search);
    const getParamsObj = Array.from(getParams.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: getParams.get(val) }),
      {}
    );

    const qapplication_id = getParamsObj.application_id;
    const qemail = getParamsObj.email;
    const qmobile = getParamsObj.mobile;
    if (qapplication_id && (qemail || qmobile)) {
      Helpers.token.set(qapplication_id, 'signup:application_id');
      Helpers.token.set(qemail || qmobile, qemail ? 'signup:email' : 'signup:mobile');
      history.push('/signin');
    } else if (qapplication_id || qemail || qmobile) {
      history.push('/signin');
      this.setState({ dataError: true, dataExtracted: true });
    }

    const application_id = Helpers.token.get('signup:application_id');
    const email = Helpers.token.get('signup:email');
    const mobile = Helpers.token.get('signup:mobile');

    if (application_id && (email || mobile)) {
      const { signup } = this.props;
      signup(
        application_id,
        email || mobile,
        getParamsObj,
        response => {
          if (response && response.data && response.data.redirect) {
            Helpers.notification.success(response.message);
            setTimeout(() => {
              window.location = response.data.redirect;
            }, 1000);
          } else {
            this.setShow('options');
            this.setState({
              applicationId: response.data.application_id || 0,
              dataExtracted: true,
              email: response.data.email || '',
              mobile: response.data.mobile.replace('234', '') || '',
            });
            Helpers.notification.success(response.message);
          }
        },
        () => {
          this.setState({ dataExtracted: true });
        }
      );
      Helpers.token.remove('signup:application_id');
      Helpers.token.remove('signup:email');
      Helpers.token.remove('signup:mobile');
    } else {
      this.setState({ dataExtracted: true });
    }
  }

  componentDidUpdate = () => {
    const { dataError, paramCheck } = this.state;
    if (paramCheck) {
      this.handleParamCheck();
    }
    if (dataError) {
      Helpers.notification.error('Authorization data is incomplete.');
      setTimeout(() => {
        window.location = '/signin';
      }, 6000);
    }
  }

  change = type => e => this.setState({ [type]: e.target.value });

  handleInputBlur = e => {
    const {
      invalidMessage, callingCode, email, mobile,
    } = this.state;
    switch (e.target.name) {
    case 'mobile':
      if (!(this.validatePhoneNumber(`+${callingCode}${mobile.trim()}`))) {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            mobile: 'Invalid mobile number',
          },
        });
      } else {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            mobile: '',
          },
        });
      }
      break;
    case 'email':
      if (!(/^[^@]+@[^@]+\.[^@]+$/.test(email.trim()))) {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            email: 'Invalid email address',
          },
        });
      } else {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            email: '',
          },
        });
      }
      break;
    default:
      break;
    }
  }

  handleInputFocus = e => {
    const { invalidMessage } = this.state;
    switch (e.target.name) {
    case 'mobile':
      if (invalidMessage.mobile) {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            mobile: '',
          },
        });
      }
      break;
    case 'email':
      if (invalidMessage.email) {
        this.setState({
          invalidMessage: {
            ...invalidMessage,
            email: '',
          },
        });
      }
      break;
    default:
      break;
    }
  }

  setPasswordType = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  setCountryCode = arg => {
    const countryCode = arg.target.value;
    const callingCode = countries[countryCode].phone;
    this.setState({ callingCode, countryCode });
  }

  signin = () => {
    const {
      callingCode, password, persistLogin, activeTab, email, mobile,
    } = this.state;
    const emailOrMobile = activeTab === 0 ? mobile : email;
    const { signin, history } = this.props;
    const emailOrMobileTrim = emailOrMobile.trim();
    if (!(emailOrMobileTrim && password)) {
      Helpers.notification.error('To continue fill in the form correctly.');
    } else if (activeTab === 0) {
      const finalEmailOrMobile = `+${callingCode}${emailOrMobileTrim}`;
      const validateMobile = this.validatePhoneNumber(finalEmailOrMobile);
      if (validateMobile) {
        signin(finalEmailOrMobile, password, history, persistLogin, 'mobile');
      } else {
        Helpers.notification.error('Incorrect mobile number format!');
      }
    } else {
      const validateEmail = /^[^@]+@[^@]+\.[^@]+$/.test(emailOrMobileTrim);
      if (validateEmail) {
        signin(emailOrMobileTrim, password, history, persistLogin, 'email');
      } else {
        Helpers.notification.error('Incorrect email address format!');
      }
    }
  }

  handlePersistLogin = () => {
    const { persistLogin } = this.state;
    this.setState({ persistLogin: !persistLogin });
  }

  validatePhoneNumber = mobile => {
    const { countryCode } = this.state;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const country = { code: countryCode };

    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      return true;
    }

    try {
      if (
        !phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(
            mobile,
            country.code
          ),
          country.code
        )
      ) {
        return false;
      }
    } catch (e) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return false;
    }
    return true;
  }

  setShow = choice => {
    const { show } = this.state;
    this.setState({ prevShow: show, show: choice });
  }

  back = () => {
    this.setShow('options');
  }

  resendLink = () => {
    const { signon } = this.props;
    const { email, applicationId } = this.state;
    if (applicationId === 0) {
      Helpers.notification.error('Partner was not specified.');
    } else {
      signon(
        applicationId,
        email,
        'email',
        null,
        response => {
          if (response && response.data && response.data.redirect) {
            Helpers.notification.success(response.message);
            setTimeout(() => {
              window.location = response.data.redirect;
            }, 1000);
          } else {
            Helpers.notification.success(response.message);
          }
        }
      );
    }
  }

  render() {
    const {
      password, showPassword, persistLogin, countryCode, activeTab, email, mobile, invalidMessage, dataExtracted, show, prevShow, applicationId,
    } = this.state;
    const { user: { loading } } = this.props;
    if (!dataExtracted) {
      return (
        <Loading size="big" />
      );
    }

    if (show === 'options') {
      return (
        <SigninOptions next={this.setShow} data={{ application_id: applicationId, email, mobile }} resend={this.resendLink} />
      );
    }

    return (
      <Page
        title="Signin"
        alignContentMobile="align-left"
        background="illustration-1"
        back={this.back}
        noPrev={prevShow !== 'options'}
        hasHeader
        hasLogo
        lm={prevShow !== 'options' ? '0' : '20px 0 0 0'}
        hm={prevShow !== 'options' ? '65px 0 0 0' : '40px 0 0 0'}
        footer={<></>}
      >
        <div className="heading signin">
          Welcome Back
        </div>

        <div className="subheading signin">
          Carrot provides a quick line of credit so you never have to liquidate assets
        </div>

        <Tabs
          current={num => this.setState({ activeTab: num })}
          tabs={[
            {
              component: (<div key={1} />),
              title: 'Phone Number',
            },
            {
              component: (<div key={2} />),
              title: 'Email',
            },
          ]}
        />

        {activeTab === 0 ? (
          <DoubleInput
            otherClasses="signin-input"
            leftInput={(
              <div className="mobile_code_select">
                <Input
                  placeholder="Mobile"
                  type="select"
                  name="countryCode"
                  id="country_code_select"
                  onChange={this.setCountryCode}
                  value={countryCode}
                  options={[
                    { label: 'NG (+234)', value: 'NG' }, { label: 'US (+1)', value: 'US' },
                  ].concat(countriesKeys.map(country => ({
                    label: `${country} (+${countries[country].phone})`,
                    value: country,
                  })))}
                />
              </div>
            )}
            rightInput={
              <Input placeholder="" onChange={this.change('mobile')} value={mobile} type="number" required name="mobile" onBlur={this.handleInputBlur} onFocus={this.handleInputFocus} />
            }
            invalidMessage={invalidMessage.mobile}
          />
        ) : (
          <div className="signin-input">
            <Input placeholder="Email" type="text" onChange={this.change('email')} value={email} required name="email" onBlur={this.handleInputBlur} onFocus={this.handleInputFocus} invalidMessage={invalidMessage.email} />
          </div>
        )}

        <div className="signin-input password_input_show">
          <Input placeholder="Password" type={showPassword ? 'text' : 'password'} onChange={this.change('password')} value={password} />
          <p alt="Show password" className="password_show_icon" onClick={this.setPasswordType}>{showPassword ? 'Hide' : 'Show'}</p>
        </div>

        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                color="default"
                checked={persistLogin}
                onChange={this.handlePersistLogin}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  '&.Mui-checked': {
                    color: '#00ab84',
                  },
                }}
              />
)}
            label={<p className="remember-me-label">Remember Me</p>}
            sx={{
              color: '#353535', justifyContent: 'left', m: 2, ml: '-12px', opacity: 0.9, p: 0,
            }}
          />
        </FormGroup>

        <div className="signin-button">
          <Button
            loading={loading.some(url => url === '/user/signin')}
            onClick={this.signin}
            size="large"
            background="light"
            disabled={password === ''}
          >
            LOG IN
          </Button>
        </div>

        <Link to="/forgot">
          <div className="footer signin">
            Forgot Password?
          </div>
        </Link>
        <Link to="/signon">
          <div className="footer signin">
            Use one-time signin
          </div>
        </Link>
        <Link to="/signup">
          <div className="footer signin">
            Don&apos;t have an account?
          </div>
        </Link>
      </Page>
    );
  }
}

export default Signin;
