import React from 'react';

import { Loading } from '../components';

import * as Helpers from '../helpers';

class Onboarding extends React.Component {
  componentDidMount() {
    const {
      load, history, match: { params: { application_id, code } },
    } = this.props;

    load(response => {
      Helpers.token.set(response.data.token);
      Helpers.token.set(application_id, 'user:application_id');
      history.push(`/offer?applicationid=${application_id}`);
    }, response => {
      if (response && response.data && response.data.token) {
        Helpers.token.set(response.data.token);
        Helpers.token.set(application_id, 'user:application_id');
        history.push(`/offer?applicationid=${application_id}`);
        return Helpers.notification.error(
          'A partial error occurred with your authentication. Check with support.'
        );
      }

      history.push('/signin');
      return Helpers.notification.error(
        'We were unable to authenticate you, try again.'
      );
    }, code);
  }

  render = () => <Loading size="big" />;
}

export default Onboarding;
