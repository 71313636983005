import React, { Component } from 'react';

import CurrentBalanceCard from './CurrentBalanceCard';

class ApplicationCard extends Component {
  render() {
    const {
      app, info, loading, blacklist, refresh, application_id,
    } = this.props;
    return (
      <div id="application-card" style={{ backgroundColor: app.backgroundColor }}>
        <CurrentBalanceCard
          loading={loading}
          application_id={application_id}
          app={app}
          info={info}
          blacklist={blacklist}
          refresh={refresh}
        />
        {app.banner && (
        <div className="app-banner">
          <img src={/^http/.test(app.banner) ? app.banner : `data:image/svg+xml;utf8;base64,${encodeURIComponent(app.banner)}`} alt="" />
        </div>
        )}
      </div>
    );
  }
}

export default ApplicationCard;
