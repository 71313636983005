import React, { Component } from 'react';

import whiteLogoMark from '../assets/images/carrot-logo-mark-white.png';
// import whiteLogoMark from '../assets/images/carrot-logo-mark-white-xmas.png'; // xmas

import * as Helpers from '../helpers';

class UserIcon extends Component {
  handleToggleMenu = () => {
    const { toggleMenu, windowWidth } = this.props;
    if (windowWidth < 768) {
      toggleMenu();
    }
  }

  render() {
    const {
      userData,
    } = this.props;
    return (
      <div id="dashboard-logo-welcome-name" onClick={this.handleToggleMenu}>
        <div id="dashboard-carrot-logo-mark">
          {userData && userData.firstname && Helpers.capitalizeFirstLetter3(userData.firstname.trim()[0])
            ? (
              <b>
                {userData && userData.firstname && Helpers.capitalizeFirstLetter3(userData.firstname.trim()[0])}
              </b>
            )
            : (
              <img src={whiteLogoMark} alt="logo mark" />
            )}
        </div>
        <div>
          <div id="dashboard-welcome">
            Welcome,
          </div>
          <div id="dashboard-user-name">
            {userData && userData.firstname && Helpers.capitalizeFirstLetter3(userData.firstname.trim())}
            <span role="img" aria-label="waving emoji">👋</span>
          </div>
        </div>
      </div>
    );
  }
}

export default UserIcon;
