import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FiInfo } from 'react-icons/fi';

import arrowDown from '../assets/images/arrow-down.svg';

const selectStyles = {
  container: oldStyles => ({
    ...oldStyles,
    marginBottom: 0,
    width: 'calc(100% - 1px)',
  }),
  control: (oldStyles, { isFocused }) => ({
    ...oldStyles,
    ':active': {
      border: 'none',
      borderBottom: '1px solid #E9EAEE',
      outline: 'none',
    },
    ':hover': {
      border: isFocused ? 'none' : 'none',
      outline: 'none',
    },
    '> div': { padding: 0 },
    '> div:nth-of-type(2) > div': { marginRight: -10 },
    '> div:nth-of-type(2) > span': { width: 0 },
    backgroundColor: 'transparent',
    border: isFocused ? 'none' : 'none',
    borderRadius: 0,
    boxShadow: 'none',
    color: '#353535',
    fontFamily: 'Gilroy-Regular',
    fontSize: '12px',
    lineHeight: '18.2px',
    marginBottom: 0,
    marginTop: 0,
    minHeight: '49px',
    opacity: 1,
    outline: 'none',
    padding: 0,
    paddingLeft: '5px',
    paddingRight: 0,
    width: '100%',
  }),
  dropdownIndicator: oldStyles => ({
    ...oldStyles,
    flexGrow: 1,
    padding: '0 0 0 0',
    svg: {
      color: '#00AB84',
      width: '13px',
    },
  }),
  indicatorsContainer: oldStyles => ({
    ...oldStyles,
    alignSelf: 'center',
    margin: '0 5% 3px 0 !important',
    minWidth: '13px',
  }),
  input: oldStyles => ({ ...oldStyles, padding: 0 }),
  menu: oldStyles => ({
    ...oldStyles,
    borderRadius: 0,
    borderTop: 0,
    marginTop: 0,
  }),
  option: (oldStyles, { isFocused }) => ({
    ...oldStyles,
    color: isFocused ? '#FFF' : '#353535',
    cursor: 'pointer',
    fontFamily: 'Gilroy-Regular',
    fontSize: '14px',
    lineHeight: '18.2px',
    marginTop: 0,
  }),
  placeholder: oldStyles => ({
    ...oldStyles,
    margin: 0,
  }),
};

class Input extends React.Component {
  state = {
    newValue: '',
  };

  componentDidMount() {
    const { value } = this.props;
    this.onChange(value);
  }

  onChange = newValue => {
    const { onChange } = this.props;
    this.setState({
      newValue,
    }, () => {
      onChange({ target: { value: newValue } });
    });
  }

  render() {
    const {
      onChange, type, value, placeholder, options, disabled, autocomplete, name, id, children, required, invalidMessage, onBlur, onFocus, noPlaceholder,
    } = this.props;
    const { newValue } = this.state;
    const noPlaceholderStyles = noPlaceholder ? {
      ...selectStyles,
      option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? '#FE5001' : '#FFF',
        color: isFocused ? '#FFF' : '#353535',
      }),
    } : {
      ...selectStyles,
      option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? 'var(--main-color)' : '#FFF',
        color: isFocused ? '#FFF' : '#353535',
      }),
    };
    switch (type) {
    case 'select':
      return (
        <div id="input-div">
          {noPlaceholder ? '' : <div className="label">{placeholder}</div>}
          <Select
            onChange={option => this.onChange(option.value)}
            value={options.find(option => option.value === value)}
            placeholder={placeholder}
            styles={noPlaceholderStyles}
            options={options}
            isDisabled={disabled}
            autocomplete="nope"
            autoFocus={false}
            name={name}
          />
          {(invalidMessage) && (
          <div>
            <FiInfo className="input-error-icon icon-error" />
            <span className="input-error-text">
              {invalidMessage}
            </span>
          </div>
          )}
        </div>
      );
    case 'radio':
      return (
        <div className="radio-group">
          {options.map(option => (
            <div key={option.value} className="radio-item" onClick={() => this.onChange(option.value)}>
              <span className={`radio-button${newValue === option.value && value === option.value ? ' active' : ''}`}>
                <div />
              </span>
              {option.label}
            </div>
          ))}
        </div>
      );

    case 'custom-select':
      return (
        <div id="input-div">
          <div className="label">{placeholder}</div>
          <img className="select-dropdown-icon" src={arrowDown} alt="Arrow Down" />
          <select
            name={name}
            id={id}
            onChange={onChange}
            className="select"
            type="text"
          >
            {children}
          </select>
        </div>
      );

    default:
      return (
        <div id="input-div">
          <div className="label">{placeholder}</div>
          <input
            disabled={disabled}
            className="input"
            onChange={onChange}
            type={type}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete={autocomplete}
            required={required}
            name={name}
          />
          {(invalidMessage) && (
          <div>
            <FiInfo className="input-error-icon icon-error" />
            <span className="input-error-text">
              {invalidMessage}
            </span>
          </div>
          )}
        </div>
      );
    }
  }
}

Input.propTypes = {
  noPlaceholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
    }).isRequired
  ),
  placeholder: PropTypes.node,
  type: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
};

export default Input;
