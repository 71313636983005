import {
  APPLICATION_GET_USER,
  APPLICATION_GET_ALL,
  APPLICATION_CLEAR
} from '../actions';

const initialState = {
  all: [],
  user: [],
};

export default function (state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
  case APPLICATION_GET_ALL:
    return {
      ...state,
      all: payload.data.applications,
    };

  case APPLICATION_GET_USER:
    return {
      ...state,
      user: payload.data.applications,
    };

  case APPLICATION_CLEAR:
    return {
      ...state,
      all: [],
      user: [],
    };

  default:
    return state;
  }
}
