import React from 'react';

const Section1 = props => {
  const {
    background, heading, subheading, CTA,
  } = props;

  return (
    <section id="section-1" className={background}>
      <div className="content-box">
        {heading && (
          <div className="heading">
            {heading}
          </div>
        )}
        {subheading && (
          <div className="content-text">
            {subheading}
          </div>
        )}
        {CTA && (
          <div className="cta">
            {CTA}
          </div>
        )}
      </div>
    </section>
  );
};

export default Section1;
