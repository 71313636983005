import React, { useState, useLayoutEffect, useCallback } from 'react';
import sha256 from 'sha256';
import { Container, Row, Col } from 'react-bootstrap';

import { Empty, SkeletonComponent } from '../../../components';

import * as Helpers from '../../../helpers';

import recentIcon from '../../../assets/images/recent-icon.svg';
import arrowRight from '../../../assets/images/arrow-right.svg';
import arrowDown from '../../../assets/images/arrow-down.svg';

const RepaymentTable = ({
  data, updateLock, getSingleRepayment, repaymentLimit, repaymentOffset, application_id, loading,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const [currentKey, setCurrentKey] = useState(0);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  // eslint-disable-next-line no-unused-vars
  const [winWidth, winHeight] = useWindowSize();

  const toggleMore = key => () => {
    if (key === currentKey) {
      setDropdown(!dropdown);
    } else {
      setCurrentKey(key);
      setDropdown(true);
    }
  };

  const handleSingleRepaymentRefresh = useCallback(repayment_id => e => {
    e.stopPropagation();
    updateLock(repayment_id, res => {
      getSingleRepayment(repayment_id);
      Helpers.notification.success(res.message);
    }, res => {
      Helpers.notification.error(res.message);
    });
  }, [getSingleRepayment, updateLock]);

  if (loading.some(
    url => url
      === `/repayment?limit=${repaymentLimit}&offset=${repaymentOffset}&application_id=${application_id}`
      || url === `/repayment?limit=${repaymentLimit}&offset=${repaymentOffset}`
  )) return <SkeletonComponent />;

  if (data.length <= 0) return <Empty />;

  return (
    <>
      {data.length > 0 && data.map((repayment, i) => {
        const principal = parseFloat(repayment.loan.loanPrincipal);
        if ((parseInt(repayment.principal, 10) === 0 && parseInt(repayment.interest, 10) === 0) || parseInt(repayment.principal, 10) < 0 || parseInt(repayment.interest, 10) < 0) {
          return <></>;
        }
        return (
          <div key={repayment.repayment_id}>
            {/* Web */}
            {winWidth >= 765 && (
              <div key={`web${repayment.repayment_id}`} className="dashboard-activity">

                <Container>
                  <Row className="align-items-center upper" onClick={toggleMore(i)}>
                    <Col xs={3}>
                      <div className="activity-icon-item">
                        <img src={recentIcon} alt="" className="recent-icon" />
                        <div className="activity-action-items">
                          <div className="activity-default-text bold">
                            Repayment
                          </div>
                          <div className="activity-default-text">
                            {repayment.date.substr(0, 10)}
                            {/* 2 days ago */}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div className="activity-default-text value">
                        REF:
                        {' '}
                        {sha256(repayment.repayment_id).substr(0, 8)}
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-default-text value">
                        $
                        {Helpers.math.divide(Helpers.math.add(parseInt(repayment.principal, 10), parseInt(repayment.interest, 10)), 100).toFixed(2)}
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items rep-ref single">
                        <div className="activity-default-text refresh single">
                          {
                      !repayment.recorded
                        ? (
                          <u onClick={handleSingleRepaymentRefresh(repayment.repayment_id)}> Refresh </u>
                        )
                        : <div className="refresh-button">Refresh</div>
                    }

                        </div>
                      </div>
                    </Col>
                    <Col xs={1}>
                      <div className="activity-dropdown-icon">
                        {dropdown === true && currentKey === i
                          ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                          : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
                      </div>
                    </Col>
                  </Row>
                </Container>

                {dropdown === true && currentKey === i && (
                <Container>
                  <Row className="lower">
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Loan Reference
                        </div>
                        <div className="activity-default-text bold value">
                          {sha256(repayment.loan.loan_id).substr(0, 8)}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Application
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.application.applicationName}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Principal (% of total)
                        </div>
                        <div className="activity-default-text bold value">
                          $
                          {Helpers.formatMoney(Helpers.math.divide(repayment.principal, 100))}
                          {' '}
                          (
                          {(repayment.principal <= 0 ? 0 : Helpers.math.multiply(100, (Helpers.math.divide(repayment.principal, principal)))).toFixed(2)}
                          %)
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Interest
                        </div>
                        <div className="activity-default-text bold value">
                          $
                          {(Helpers.math.divide(parseInt(repayment.interest, 10), 100)).toFixed(2)}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="lower">
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Rate
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.rate}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Initiated By
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.triggered === 'user' ? 'You' : repayment.triggered === 'application' ? repayment.application.applicationName : 'Carrot'}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Made via
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.processor.processorName}
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Payment Reference
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.reference}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="lower">
                    <Col>
                      <div className="activity-action-items">
                        <div className="activity-default-text not-black">
                          Date
                        </div>
                        <div className="activity-default-text bold value">
                          {repayment.date}
                        </div>
                      </div>
                    </Col>
                    <Col />

                    <Col />

                    <Col />

                  </Row>
                </Container>
                )}

              </div>
            )}

            {/* Mobile */}
            {winWidth < 765 && (
              <div key={`mobile${repayment.repayment_id}`} className="dashboard-activity">
                <div className="item level-1" onClick={toggleMore(i)}>
                  <div className="activity-icon-item">
                    <img src={recentIcon} alt="" className="recent-icon" />
                    <div className="activity-action-items">
                      <div className="activity-default-text bold">
                        Repayment
                      </div>
                      <div className="activity-default-text">
                        {repayment.date.substr(0, 10)}
                      </div>
                    </div>
                  </div>
                  <div className="activity-value-icon">
                    <div className="activity-default-text bold value top">
                      $
                      {Helpers.math.divide(Helpers.math.add(parseInt(repayment.principal, 10), parseInt(repayment.interest, 10)), 100).toFixed(2)}
                    </div>
                    {dropdown === true && currentKey === i
                      ? <img src={arrowDown} alt="dropdown icon" onClick={toggleMore(i)} />
                      : <img src={arrowRight} alt="dropdown icon" onClick={toggleMore(i)} />}
                  </div>
                </div>

                {dropdown === true && currentKey === i && (
                  <>

                    <Container className="level-3-container">
                      <Row className="level-3">
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Repayment Reference
                            </div>
                            <div className="activity-default-text bold value">
                              {sha256(repayment.repayment_id).substr(0, 8)}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Loan Reference
                            </div>
                            <div className="activity-default-text bold value">
                              {sha256(repayment.loan.loan_id).substr(0, 8)}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Application
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.application.applicationName}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Principal (% of total)
                            </div>
                            <div className="activity-default-text bold value">
                              $
                              {Helpers.formatMoney(Helpers.math.divide(repayment.principal, 100))}
                              {' '}
                              (
                              {(repayment.principal <= 0 ? 0 : Helpers.math.multiply(100, (Helpers.math.divide(repayment.principal, principal)))).toFixed(2)}
                              %)
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Interest
                            </div>
                            <div className="activity-default-text bold value">
                              $
                              {(Helpers.math.divide(parseInt(repayment.interest, 10), 100)).toFixed(2)}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Rate
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.rate}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Initiated By
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.triggered === 'user' ? 'You' : repayment.triggered === 'application' ? repayment.application.applicationName : 'Carrot'}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Made via
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.processor.processorName}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Payment Reference
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.reference}
                            </div>
                          </div>
                        </Col>

                        <Col xs={6} sm={6} className="no-gutters-right col-margin">
                          <div className="activity-action-items">
                            <div className="activity-default-text bold not-black">
                              Date
                            </div>
                            <div className="activity-default-text bold value">
                              {repayment.date}
                            </div>
                          </div>
                        </Col>
                        <Col xs={6} sm={6} className="no-gutters-right col-margin" />
                        <Col xs={6} sm={6} className="no-gutters-right col-margin" />
                      </Row>

                    </Container>

                    <div className="item level-4">
                      <div className="activity-default-text bold repay">
                        <div className="refresh-button">Repay</div>
                      </div>
                      <div className="vertical-line" />
                      <div className="activity-default-text bold refresh">
                        {
                      !repayment.recorded
                        ? (
                          <u onClick={handleSingleRepaymentRefresh(repayment.repayment_id)}> Refresh </u>
                        )
                        : <div className="refresh-button">Refresh</div>
                    }
                      </div>
                      <div className="vertical-line" />
                      <div className="activity-default-text bold cancel">
                        <div className="refresh-button">Refresh</div>
                      </div>
                    </div>
                  </>
                )}

              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default RepaymentTable;
