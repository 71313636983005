import React, { Component } from 'react';

import arrowDown from '../assets/images/arrow-down-white.svg';
import arrowDownGreen from '../assets/images/arrow-down.svg';

import * as Helpers from '../helpers';

class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  handleSwitchPortfolio = application_id => {
    Helpers.token.set(application_id, 'user:application_id');
    this.handleDropdownToggle();
    const { switchPortfolio } = this.props;
    switchPortfolio(application_id);
  }

  handleDropdownToggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  handleGlobalClick = event => {
    const dropdownNode = this.dropdownRef.current;

    if (dropdownNode && !dropdownNode.contains(event.target)) {
      this.setState({ show: false });
    }
  };

  render() {
    const {
      show,
    } = this.state;
    const { app: { icon, name }, applications, size } = this.props;

    return (
      <div id="multi-select-div" ref={this.dropdownRef}>
        {size === 'big'
          ? (
            <div className="current-portfolio" style={{ backgroundColor: Number(Helpers.token.get('user:application_id')) === 0 ? '#fe5000' : '#036149' }} onClick={this.handleDropdownToggle}>
              <div className="dot" />
              <img className="portfolio-icon" src={Number(Helpers.token.get('user:application_id')) === 0 || /^http/.test(icon) ? icon : `data:image/svg+xml;utf8;base64,${encodeURIComponent(icon)}`} alt="" />
              <div className="name-text">
                {name}
              </div>
              <div className="portfolio-switch">
                <div className="text">
                  Switch Portfolio
                </div>
                <img className="dropdown-icon" src={arrowDown} alt="" />
              </div>
            </div>
          )
          : (
            <div className="current-portfolio-mobile-container" onClick={this.handleDropdownToggle}>
              <div className="current-portfolio-mobile" style={{ backgroundColor: Number(Helpers.token.get('user:application_id')) === 0 ? '#fe5000' : '#036149' }}>
                <img className="portfolio-icon" src={Number(Helpers.token.get('user:application_id')) === 0 || /^http/.test(icon) ? icon : `data:image/svg+xml;utf8;base64,${encodeURIComponent(icon)}`} alt="" />
              </div>
              <img className="dropdown-icon" src={arrowDownGreen} alt="" />
            </div>
          )}

        {show
          ? size === 'big'
            ? (
              <div id="portfolio-dropdown">
                {applications.filter(vendor => Number(vendor.application_id) !== Number(Helpers.token.get('user:application_id')))
                  .map((vendor, i) => (
                    <div key={`multiselect-web-${i}`}>
                      <div className="dropdown-portfolio-item" style={{ backgroundColor: Number(vendor.application_id) === 0 ? '#fe5000' : '#036149' }} onClick={() => this.handleSwitchPortfolio(vendor.application_id)}>
                        <div className="dot" />
                        <img className="portfolio-icon" src={Number(vendor.application_id) === 0 || /^http/.test(vendor.images.icon) ? vendor.images.icon : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.icon)}`} alt="" />
                        <div className="name-text">
                          {vendor.name}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )
            : (
              <div id="portfolio-dropdown-mobile">
                {applications.filter(vendor => Number(vendor.application_id) !== Number(Helpers.token.get('user:application_id')))
                  .map((vendor, i) => (
                    <div key={`multiselect-mobile-${i}`}>
                      <div className="current-portfolio-mobile" style={{ backgroundColor: Number(vendor.application_id) === 0 ? '#fe5000' : '#036149' }} onClick={() => this.handleSwitchPortfolio(vendor.application_id)}>
                        <img className="portfolio-icon" src={Number(vendor.application_id) === 0 || /^http/.test(vendor.images.icon) ? vendor.images.icon : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.icon)}`} alt="" />
                      </div>
                    </div>
                  ))}
              </div>
            )
          : ''}
      </div>
    );
  }
}

export default MultiSelect;
