import React, { useState } from 'react';
import {
  Navbar, Footer, Section1, Button, GetStarted, AnimatedSection
} from '../../components';

import './Business.css';

import percent30 from '../../assets/images/business-30-percent.png';
import recycle from '../../assets/images/business-recycle.png';
import cash from '../../assets/images/business-cash.png';
import percent42 from '../../assets/images/business-42-percent.png';
import AUMCard from '../../components/AUMCard';
import RevenueCard from '../../components/RevenueCard';
import plug from '../../assets/images/business-plug.png';

const Business = ({ contact, user: { loading } }) => {
  const heading = (
    <>
      <div>
        Empowering your
      </div>
      <div>
        {' '}
        <span>customers</span>
        {' '}
        just got easier
      </div>
    </>
  );

  const [businessEmail, setBusinessEmail] = useState('');

  const handleEmailInput = e => {
    setBusinessEmail(e.target.value);
  };

  const CTA = (
    <div className="section-1 business-section-1">
      <input placeholder="Work Email" name="email" onChange={handleEmailInput} value={businessEmail} />
      <a href="#getStartedForm">
        <Button cta>
          Contact Sales
        </Button>
      </a>
    </div>
  );

  return (
    <div>
      <div className="navbar-wrap">
        <Navbar />
      </div>

      <Section1
        background="business"
        heading={heading}
        subheading="Give your customers more cash – access to a credit line backed by their assets. No hidden fees, No prepayment fees. "
        CTA={CTA}
      />

      <div>
        <div className="HeroSection">
          <AnimatedSection className="container">
            <div className="row  d-flex justify-content-center text-center mt-3 add-gutter">
              <div className="col-md-6 MainHeading">
                <p>
                  Perfect for businesses with up to $3M Assets Under Management
                </p>
              </div>
              <div className="MainSecondHeading">
                <h1>
                  Grow your business with
                  {' '}
                  <span className="carrot">Carrot</span>
                  <span className="carrot">.</span>
                </h1>
              </div>
              <div className="content-text">
                <p>
                  More money, no problems – give your customers access to credit
                  <br />
                  that traditional institutions can’t or won’t give them.
                  <br />
                  Best part? you get to increase your revenue as well.
                </p>
              </div>
            </div>
          </AnimatedSection>

          <div className="container-fluid">
            <div className="row d-flex justify-content-center text-center mt-5">
              <AnimatedSection className="col-md-5" variant="fade-in-sectionLeft">
                <AUMCard
                  image={percent30}
                  Icon={recycle}
                  Heading="AUM Retainment Rate"
                  dec="  Carrot helps businesses reduce cyclical and emergency
                withdrawals to the minimum which boosts asset retainment rate."
                />
              </AnimatedSection>
              <AnimatedSection className="col-md-5" variant="fade-in-sectionRight">
                <RevenueCard
                  image={percent42}
                  Icon1={cash}
                  Heading="Revenue Increase"
                  dec=" Make money empowering your users- Participate in a healthy revenue sharing agreement on each customer transaction "
                />
              </AnimatedSection>
            </div>
          </div>

          <div className="container ">
            <div className="row d-flex justify-content-center">
              <div className="col-md-11">
                <div className="row lastbox d-flex justify-content-center mt-5 add-gutter">
                  <div className="col-md-5 p-3 m-4 flex-grow-1 align-self-center">
                    <p className="heading-text">
                      Easily integrate your application
                    </p>
                    <p className="content-text">
                      Our platform integration is simple get started
                      {' '}
                      <br />
                      today.
                      {' '}
                      <br />
                      We align with your team and make all necessary custom configuration.
                    </p>
                    <Button cta id="documentBtn">
                      See our documentation
                    </Button>
                  </div>
                  <AnimatedSection className="col-md-5" variant="fade-in-sectionRight">
                    <img src={plug} alt="plug" className="img-fluid" />
                  </AnimatedSection>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatedSection>
        <GetStarted contact={contact} businessEmail={businessEmail} loading={loading} />
      </AnimatedSection>
      <AnimatedSection>
        <Footer />
      </AnimatedSection>
    </div>
  );
};

export default Business;
