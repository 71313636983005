import React from 'react';

class Signup extends React.Component {
  render() {
    return (
      <div id="not-found">
        404
      </div>
    );
  }
}

export default Signup;
