import React from 'react';
import PropTypes from 'prop-types';

import { FiInfo } from 'react-icons/fi';

class DoubleInput extends React.Component {
  render() {
    const {
      leftInput, rightInput, invalidMessage, otherClasses,
    } = this.props;
    return (
      <>
        <div className={`double_input ${otherClasses}`}>
          {leftInput}
          {rightInput}
        </div>
        {(invalidMessage) && (
          <div>
            <FiInfo className="input-error-icon icon-error" />
            <span className="input-error-text">
              {invalidMessage}
            </span>
          </div>
        )}
      </>
    );
  }
}

DoubleInput.propTypes = {
  invalidMessage: PropTypes.string,
  leftInput: PropTypes.node.isRequired,
  otherClasses: PropTypes.string,
  rightInput: PropTypes.node.isRequired,
};

export default DoubleInput;
